import React from "react";
import "./Group429.css";

function Group429(props) {
  const { className, onClick } = props;

  return (
    <div className={`group-429 ${className || ""}`} onClick={onClick}>
      <img className="cancel-6" src="/img/cancel@2x.svg" />
    </div>
  );
}

export default Group429;
