import React from "react";
import "./header-verify.css";

const HeaderVerify = () => {
  return (
    <header className="verify-header">
      <div className="navigation-menu">
        <div className="home-menu-button khula-semi-bold-white-16px valign-text-middle">
          Home
        </div>
        <div className="about-menu-button khula-semi-bold-white-16px valign-text-middle">
          About
        </div>
        <div className="what-we-offer-menu-button khula-semi-bold-white-16px valign-text-middle">
          What We Offer
        </div>
        <div className="how-it-works-menu-button khula-semi-bold-white-16px valign-text-middle">
          How It Works
        </div>
        <div className="contact-menu-button khula-semi-bold-white-16px valign-text-middle">
          Contact
        </div>
      </div>
      <h1 className="verification valign-text-middle">Verification</h1>
    </header>
  );
};

export default HeaderVerify;
