import React from "react";
import "./weighting-step-data.css";

const WeightingStepData = () => {
  return (
    <div className="group-394 poppins-semi-bold-white-13px">
      <div className="group-383">
        <div className="weighting-2 poppins-semi-bold-astronaut-15px">
          Weighting
        </div>
        <img className="weighting-icon" src="/img/weighting-icon.svg" />
      </div>
      <p className="fishery-name-james-miles">
        <span className="poppins-semi-bold-tundora-13px">
          Fishery name:&nbsp;&nbsp;
        </span>
        <span className="span1-2 text">James Miles</span>
      </p>
      <div className="group-394-item">
        <span className="poppins-semi-bold-tundora-13px">
          Lot size:&nbsp;&nbsp;
        </span>
        <span className="span1-2 text">Small</span>
      </div>
      <p className="group-394-item">
        <span className="poppins-semi-bold-tundora-13px">
          Lot grade:&nbsp;&nbsp;
        </span>
        <span className="span1-2 text">Grade A</span>
      </p>
      <div className="group-394-item">
        <span className="poppins-semi-bold-tundora-13px">Lot weight: </span>
        <span className="span1-2 text">457kg</span>
      </div>
      <p className="group-394-item">
        <span className="poppins-semi-bold-tundora-13px">
          Catch area:&nbsp;&nbsp;{" "}
        </span>
        <span className="span1-2 text">St George’s Channel</span>
      </p>
      <p className="group-394-item">
        <span className="poppins-semi-bold-tundora-13px">
          Catch method:&nbsp;&nbsp;
        </span>
        <span className="span1-2 text">By net</span>
      </p>
      <div className="group-394-item">
        <span className="poppins-semi-bold-tundora-13px">
          Fish species:&nbsp;&nbsp;
        </span>
        <span className="span1-2 text">Pollack</span>
      </div>
      <div className="group-394-item-1">
        <span className="poppins-semi-bold-tundora-13px">PLN: </span>
        <span className="span1-2 text">
          Boat: 0409954
          <br />
          <span className="span-newline">Sealess Rock</span>
        </span>
      </div>
      <div className="group-394-item-1">
        <span className="poppins-semi-bold-tundora-13px">
          Name market operator:
          <br />
        </span>
        <span className="span1-2 text span-newline">John Klopper</span>
      </div>
    </div>
  );
};

export default WeightingStepData;
