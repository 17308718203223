import React from "react";
import "./audit-logs.css";

function AuditLogs() {
  return (
    <div className="overlap-group13-20 border-1px-gray-nurse">
      <div className="audit-logs-17 poppins-bold-mine-shaft-16px card-title">
        Audit Logs
      </div>
      <div className="divider" />
      <div className="flex-row-61">
        <p className="scheduled-2-documents-to-be-issued-27 poppins-normal-sonic-silver-13px">
          Scheduled 2 documents to be issued
        </p>
        <div className="view-140 poppins-normal-white-11px">View</div>
      </div>
      <div className="date-66 poppins-normal-fuscous-gray-11px">25/01/2022</div>
      <div className="divider" />
      <div className="flex-row-58">
        <p className="scheduled-7 poppins-normal-sonic-silver-13px">
          Scheduled 3 documents to be issued
        </p>
        <div className="view-139 poppins-normal-white-11px">View</div>
      </div>
      <div className="date-66 poppins-normal-fuscous-gray-11px">25/01/2022</div>
      <div className="divider" />
      <div className="flex-row-58">
        <p className="scheduled-7 poppins-normal-sonic-silver-13px">
          Scheduled 1 documents to be issued
        </p>
        <div className="view-139 poppins-normal-white-11px">View</div>
      </div>
      <div className="date-66 poppins-normal-fuscous-gray-11px">25/01/2022</div>
      <div className="divider" />
      <div className="flex-row-62">
        <p className="scheduled-7 poppins-normal-sonic-silver-13px">
          Scheduled 2 documents to be issued
        </p>
        <div className="view-139 poppins-normal-white-11px">View</div>
      </div>
      <div className="date-66 poppins-normal-fuscous-gray-11px">20/12/2021</div>
    </div>
  );
}

export default AuditLogs;
