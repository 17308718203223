import React from "react";
import { motion } from "framer-motion";
import "./Frame14.css";

function Frame14(props) {
  const { className } = props;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className={`frame-1-9 ${className || ""}`}
    >
      <div className="chart-name-38 poppins-bold-white-16px">Map</div>
      <div className="overlap-group2-28">
        <div className="ellipse-44-6"></div>
      </div>
    </motion.div>
  );
}

export default Frame14;
