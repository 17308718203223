import React from "react";
import { motion } from "framer-motion";
import Group381 from "../Group381";
import Group429 from "../Group429";
import "./fishing-dialog.css";

const FishingDialog = ({ onSave, onCancel }) => {
  return (
    <div className="center-dialog">
      <div className="overlay"></div>
      <div className="rectangle-135-2"></div>
      <div className="course-1 poppins-semi-bold-mine-shaft-13px">Course:</div>
      <div className="date-14 poppins-semi-bold-mine-shaft-13px">Date:</div>
      <div className="time-1 poppins-semi-bold-mine-shaft-13px">Time:</div>
      <Group381 />
      <div className="rectangle-136-1 border-1px-mercury"></div>
      <div className="rectangle-137-7 border-1px-mercury"></div>
      <div className="rectangle-138-5 border-1px-mercury"></div>
      <Group429 onClick={onCancel} />
      <div
        className="cancel-button-1 border-2px-guardsman-red-2 poppins-semi-bold-guardsman-red-15px"
        onClick={onCancel}
      >
        Cancel
      </div>
      <div
        className="save-button-1 poppins-semi-bold-white-15px"
        onClick={onSave}
      >
        Save
      </div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8 }}
        className="route-slooq text"
      >
        Route SLOOQ
      </motion.div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8 }}
        className="date-15 text"
      >
        10/06/2022
      </motion.div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8 }}
        className="text-5-1 text"
      >
        06:58:00
      </motion.div>
    </div>
  );
};

export default FishingDialog;
