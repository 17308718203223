import React, { useState } from "react";
import clsx from "clsx";
import ReportIssue from "./ReportIssue";
import "./verified-data.css";

const VerifiedData = (props) => {
  const {
    showFishing,
    showLanding,
    showWeighting,
    showAuctioning,
    showTransportation,
    showCustomClearance,
  } = props;
  const [openedFishing, setFishingOpen] = useState(false);
  const [openedLanding, setLandingOpen] = useState(false);
  const [openedWeighting, setWeightingOpen] = useState(false);
  const [openedAuctioning, setAuctioningOpen] = useState(false);
  const [openedTransportation, setTransportationOpen] = useState(false);
  const [openedCustomClearance, setCustomClearanceOpen] = useState(false);
  const [openedReportDialog, setOpenReportDialog] = useState(false);

  const toggleFishingStep = () => setFishingOpen(!openedFishing);
  const toggleLandingStep = () => setLandingOpen(!openedLanding);
  const toggleWeightingStep = () => setWeightingOpen(!openedWeighting);
  const toggleAuctioningStep = () => setAuctioningOpen(!openedAuctioning);
  const toggleTransportationStep = () =>
    setTransportationOpen(!openedTransportation);
  const toggleCustomClearanceStep = () =>
    setCustomClearanceOpen(!openedCustomClearance);

  const openReportDialog = () => {
    setOpenReportDialog(true);
  };

  const closeReportDialog = () => {
    setOpenReportDialog(false);
  };

  return (
    <>
      {showFishing && (
        <>
          <div
            className="fishing-verified-step border-top-verified-step"
            onClick={toggleFishingStep}
          >
            <img className="step-icon" src="/img/group-73-2@2x.svg" />
            <div className="step-label t1">Fishing</div>
            <img className="certified-icon" src="/img/vector@2x.svg" />
            <img
              className={clsx(
                "expand-icon",
                openedFishing && "expand-less-icon"
              )}
              src="/img/expand_more.svg"
            />
          </div>
          <div
            className={clsx(
              "step-data step-data-1-2 closed-step",
              openedFishing && "open-step"
            )}
          >
            <div className="step-data-content">
              <div className="group-428-1-xy">
                <div className="course poppins-semi-bold-tundora-13px">
                  Course:
                </div>
                <div className="route-slooq-1 text">ROUTE SLOOQ</div>
              </div>
              <div className="group-429-2-xy">
                <div className="date poppins-semi-bold-tundora-13px">Date:</div>
                <div className="date-1 text">10/06/2022</div>
              </div>
              <div className="group-430-3">
                <div className="time poppins-semi-bold-tundora-13px">Time:</div>
                <div className="text-1 text">06:58:00</div>
              </div>
              <div
                className="report-issue-button border-1-5px-bright-red"
                onClick={openReportDialog}
              >
                <div className="report-issue-button-text poppins-semi-bold-bright-red-13px">
                  Report Issue
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {showLanding && (
        <>
          <div
            className="landing-verified-step t1 border-top-verified-step"
            onClick={toggleLandingStep}
          >
            <img className="step-icon" src="/img/group-74-2@2x.svg" />
            <div className="step-label t1">Landing</div>
            <img className="certified-icon" src="/img/vector@2x.svg" />
            <img
              className={clsx(
                "expand-icon",
                openedLanding && "expand-less-icon"
              )}
              src="/img/expand_more.svg"
            />
          </div>
          <div
            className={clsx(
              "step-data step-data-1-2 closed-step",
              openedLanding && "open-step"
            )}
          >
            <div className="step-data-content">
              <div className="group-428-1-xy">
                <div className="course poppins-semi-bold-tundora-13px">
                  Course:
                </div>
                <div className="route-slooq-1 text">ROUTE SLOOQ</div>
              </div>
              <div className="group-429-2-xy">
                <div className="date poppins-semi-bold-tundora-13px">Date:</div>
                <div className="date-1 text">10/06/2022</div>
              </div>
              <div className="group-430-3">
                <div className="time poppins-semi-bold-tundora-13px">Time:</div>
                <div className="text-1 text">09:43:00</div>
              </div>
              <div
                className="report-issue-button border-1-5px-bright-red"
                onClick={openReportDialog}
              >
                <div className="report-issue-button-text poppins-semi-bold-bright-red-13px">
                  Report Issue
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {showWeighting && (
        <>
          <div
            className="weighting-verified-step border-top-verified-step"
            onClick={toggleWeightingStep}
          >
            <img className="step-icon" src="/img/group-430-3@2x.svg" />
            <div className="step-label t1">Weighting</div>
            <img className="certified-icon" src="/img/vector@2x.svg" />
            <img
              className={clsx(
                "expand-icon",
                openedWeighting && "expand-less-icon"
              )}
              src="/img/expand_more.svg"
            />
          </div>
          <div
            className={clsx(
              "step-data closed-step",
              openedWeighting && "open-step-3"
            )}
          >
            <div className="step-data-content step-data-content-3">
              <p className="group-449-item">
                <span className="poppins-semi-bold-tundora-13px">
                  Fishery name:{" "}
                </span>
                <span className="span1 text">James Miles</span>
              </p>
              <div className="group-449-item">
                <span className="poppins-semi-bold-tundora-13px">
                  Lot size:{" "}
                </span>
                <span className="span1 text">Small</span>
              </div>
              <p className="group-449-item">
                <span className="poppins-semi-bold-tundora-13px">
                  Lot grade:{" "}
                </span>
                <span className="span1 text">Grade A</span>
              </p>
              <div className="group-449-item">
                <span className="poppins-semi-bold-tundora-13px">
                  Lot weight:{" "}
                </span>
                <span className="span1 text">13kg</span>
              </div>
              <p className="group-449-item">
                <span className="poppins-semi-bold-tundora-13px">
                  Catch area:{" "}
                </span>
                <span className="span1 text">{`St George’s Channel`}</span>
              </p>
              <p className="group-449-item">
                <span className="poppins-semi-bold-tundora-13px">
                  Catch method:{" "}
                </span>
                <span className="span1 text">By net</span>
              </p>
              <div className="group-449-item">
                <span className="poppins-semi-bold-tundora-13px">
                  Fish species:{" "}
                </span>
                <span className="span1 text">Pollack</span>
              </div>
              <div className="group-449-item-1">
                <span className="poppins-semi-bold-tundora-13px">PLN: </span>
                <span className="span1 text">
                  Boat: 0409954
                  <br />
                  <span className="span-newline">Sealess Rock</span>
                </span>
              </div>
              <div className="group-449-item-1">
                <span className="poppins-semi-bold-tundora-13px">
                  Name market operator:{" "}
                </span>
                <span className="span1 text">John Klopper</span>
              </div>
              <div
                className="report-issue-button border-1-5px-bright-red"
                onClick={openReportDialog}
              >
                <div className="report-issue-button-text poppins-semi-bold-bright-red-13px">
                  Report Issue
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {showAuctioning && (
        <>
          <div
            className="auctioning-verified-step border-top-verified-step"
            onClick={toggleAuctioningStep}
          >
            <img className="step-icon" src="/img/group-431-3@2x.svg" />
            <div className="step-label t1">Auctioning</div>
            <img className="certified-icon" src="/img/vector@2x.svg" />
            <img
              className={clsx(
                "expand-icon",
                openedAuctioning && "expand-less-icon"
              )}
              src="/img/expand_more.svg"
            />
          </div>
          <div
            className={clsx(
              "step-data closed-step",
              openedAuctioning && "open-step-4"
            )}
          >
            <div className="step-data-content step-data-content-4">
              <p className="group-448-item">
                <span className="poppins-semi-bold-tundora-13px">
                  Client name:{" "}
                </span>
                <span className="span1-1 text">Devante Ltd</span>
              </p>
              <p className="group-448-item">
                <span className="poppins-semi-bold-tundora-13px">
                  Fish species:{" "}
                </span>
                <span className="span1-1 text">Pollack</span>
              </p>
              <div className="group-448-item">
                <span className="poppins-semi-bold-tundora-13px">
                  Lot grade:{" "}
                </span>
                <span className="span1-1 text">Grade A</span>
              </div>
              <div className="group-448-item">
                <span className="poppins-semi-bold-tundora-13px">
                  Lot weight:{" "}
                </span>
                <span className="span1-1 text">13kg</span>
              </div>
              <div className="group-448-item">
                <span className="poppins-semi-bold-tundora-13px">
                  Total weight:{" "}
                </span>
                <span className="span1-1 text">13kg</span>
              </div>
              <p className="group-448-item">
                <span className="poppins-semi-bold-tundora-13px">
                  # of boxes:{" "}
                </span>
                <span className="span1-1 text">1</span>
              </p>
              <p className="group-448-item">
                <span className="poppins-semi-bold-tundora-13px">
                  Fishery name:{" "}
                </span>
                <span className="span1-1 text">James Miles</span>
              </p>
              <div
                className="report-issue-button border-1-5px-bright-red"
                onClick={openReportDialog}
              >
                <div className="report-issue-button-text poppins-semi-bold-bright-red-13px">
                  Report Issue
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {showTransportation && (
        <>
          <div
            className="transportation-verified-step border-top-verified-step"
            onClick={toggleTransportationStep}
          >
            <img className="step-icon" src="/img/group-432-3@2x.svg" />
            <div className="step-label t1">Transportation</div>
            <img className="certified-icon" src="/img/vector@2x.svg" />
            <img
              className={clsx(
                "expand-icon",
                openedTransportation && "expand-less-icon"
              )}
              src="/img/expand_more.svg"
            />
          </div>
          <div
            className={clsx(
              "step-data closed-step",
              openedTransportation && "open-step-5"
            )}
          >
            <div className="step-data-content step-data-content-5">
              <p className="group-447-item">
                <span className="poppins-semi-bold-tundora-13px">
                  Client name:{" "}
                </span>
                <span className="span-11 text">Devant Ltd</span>
              </p>
              <p className="group-447-item">
                <span className="poppins-semi-bold-tundora-13px">
                  Fish species:{" "}
                </span>
                <span className="span-11 text">Pollack</span>
              </p>
              <p className="group-447-item">
                <span className="poppins-semi-bold-tundora-13px">
                  Fishery name:{" "}
                </span>
                <span className="span-11 text">James Miles</span>
              </p>
              <p className="group-447-item">
                <span className="poppins-semi-bold-tundora-13px">
                  Transporter name:{" "}
                </span>
                <span className="span-11 text">Miles Fast Ltd</span>
              </p>
              <div className="group-447-item">
                <span className="poppins-semi-bold-tundora-13px">
                  Box weight:{" "}
                </span>
                <span className="span-11 text">13kg</span>
              </div>
              <div className="group-447-item">
                <span className="poppins-semi-bold-tundora-13px">
                  Food standart approval:{" "}
                </span>
                <span className="span-11 text">#26DGKLG78</span>
              </div>
              <div className="group-447-item">
                <span className="poppins-semi-bold-tundora-13px">
                  Country origin:{" "}
                </span>
                <span className="span-11 text"> Wales</span>
              </div>
              <p className="group-447-item">
                <span className="poppins-semi-bold-tundora-13px">
                  Country of destination:{" "}
                </span>
                <span className="span-11 text">France</span>
              </p>
              <p className="place-and-time">
                <span className="poppins-semi-bold-tundora-13px">
                  Place and time of loading:{" "}
                </span>
                <br />
                <span className="span-11 text span-newline">
                  Wales, Porthcawl 10/06/2022 05:03:06
                </span>
              </p>
              <p className="place-and-time">
                <span className="poppins-semi-bold-tundora-13px">
                  Place and time destination:{" "}
                </span>
                <br />
                <span className="span-11 text span-newline">
                  France, Tourlaville 16/11/2022 13:15:08
                </span>
              </p>
              <div
                className="report-issue-button border-1-5px-bright-red"
                onClick={openReportDialog}
              >
                <div className="report-issue-button-text poppins-semi-bold-bright-red-13px">
                  Report Issue
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {showCustomClearance && (
        <>
          <div
            className="custom-clearance-verified-step border-top-verified-step"
            onClick={toggleCustomClearanceStep}
          >
            <img className="step-icon" src="/img/group-433-3@2x.svg" />
            <div className="step-label t1">Custom clearance</div>
            <img className="certified-icon" src="/img/vector@2x.svg" />
            <img
              className={clsx(
                "expand-icon",
                openedCustomClearance && "expand-less-icon"
              )}
              src="/img/expand_more.svg"
            />
          </div>
          <div
            className={clsx(
              "step-data closed-step border-bottom-verified-step",
              openedCustomClearance && "open-step-6"
            )}
          >
            <div className="step-data-content step-data-content-6">
              <div className="group-446-xx">
                <div className="flex-col-38">
                  <div className="catch-certificate-k234-h5685-dfh99 poppins-semi-bold-white-13px">
                    <span className="poppins-semi-bold-tundora-13px">
                      Catch certificate:{" "}
                    </span>
                    <span className="span-4 text">#K234H5685DFH99</span>
                  </div>
                  <p className="validating-entity-porthcalw-sd poppins-semi-bold-white-13px">
                    <span className="poppins-semi-bold-tundora-13px">
                      Validating entity:{" "}
                    </span>
                    <span className="span-4 text">Porthcawl SD</span>
                  </p>
                  <div className="exporter-name-ice poppins-semi-bold-white-13px">
                    <span className="poppins-semi-bold-tundora-13px">
                      Exporter name:{" "}
                    </span>
                    <span className="span-4 text">ICE.</span>
                  </div>
                  <p className="exporter-address-2 poppins-semi-bold-white-13px">
                    <span className="poppins-semi-bold-tundora-13px">
                      Exporter address:{" "}
                    </span>
                    <br />
                    <span className="span-4 text span-newline">
                      2-4 Dock St, Porthcawl CF36 3BL, UK
                    </span>
                  </p>
                  <div className="date-of-issuance-12112022 poppins-semi-bold-white-13px">
                    <span className="poppins-semi-bold-tundora-13px">
                      Date of issuance:{" "}
                    </span>
                    <span className="span-4 text"> 12/11/2022</span>
                  </div>
                  <p className="fao-area-st-georges-channel poppins-semi-bold-white-13px">
                    <span className="poppins-semi-bold-tundora-13px">
                      FAO area:{" "}
                    </span>
                    <span className="span-4 text">{`St George’s Channel`}</span>
                  </p>
                  <p className="master-of-vessel-bentley-grey poppins-semi-bold-white-13px">
                    <span className="poppins-semi-bold-tundora-13px">
                      Master of vessel:{" "}
                    </span>
                    <span className="span-4 text">Bentley Grey</span>
                  </p>
                  <p className="master-signature-bentley-grey poppins-semi-bold-white-13px">
                    <span className="poppins-semi-bold-tundora-13px">
                      Master signature:{" "}
                    </span>
                    <span className="poppins-medium-sonic-silver-13px">
                      Bentley Grey
                    </span>
                  </p>
                  <div className="licence-number-3205911-h3-s poppins-semi-bold-white-13px">
                    <span className="poppins-semi-bold-tundora-13px">
                      Licence number:{" "}
                    </span>
                    <span className="span-4 text">3205911H3S</span>
                  </div>
                  <p className="fish-species-pollack poppins-semi-bold-white-13px">
                    <span className="poppins-semi-bold-tundora-13px">
                      Fish species:{" "}
                    </span>
                    <span className="span-4 text">Pollack</span>
                  </p>
                  <div className="lot-weight-13kg-4 poppins-semi-bold-white-13px">
                    <span className="poppins-semi-bold-tundora-13px">
                      Lot weight:{" "}
                    </span>
                    <span className="span-4 text">13kg</span>
                  </div>
                  <p className="fishery-name-james-miles poppins-semi-bold-white-13px">
                    <span className="poppins-semi-bold-tundora-13px">
                      Fishery name:{" "}
                    </span>
                    <span className="span-4 text">James Miles</span>
                  </p>
                  <div className="lot-grade-grade-a poppins-semi-bold-white-13px">
                    <span className="poppins-semi-bold-tundora-13px">
                      Lot grade:{" "}
                    </span>
                    <span className="span-4 text">Grade A</span>
                  </div>
                  <div className="pln-boat-0409954-sealess-rock-4 poppins-semi-bold-white-13px">
                    <span className="poppins-semi-bold-tundora-13px">
                      PLN:{" "}
                    </span>
                    <span className="span-4 text">
                      Boat: 0409954
                      <br />
                      <span className="span-newline">Sealess Rock</span>
                    </span>
                  </div>
                  <p className="address-x poppins-semi-bold-tundora-13px">
                    # of boxes: 1
                  </p>
                  <div
                    className="report-issue-button border-1-5px-bright-red"
                    onClick={openReportDialog}
                  >
                    <div className="report-issue-button-text poppins-semi-bold-bright-red-13px">
                      Report Issue
                    </div>
                  </div>
                </div>
                <div className="flex-col-39 poppins-semi-bold-white-13px">
                  <p className="client-name-devante-ltd">
                    <span className="poppins-semi-bold-tundora-13px">
                      Client name:{" "}
                    </span>
                    <span className="span-4 text">Devante Ltd</span>
                  </p>
                  <div className="total-weight-13kg">
                    <span className="poppins-semi-bold-tundora-13px">
                      Total weight:{" "}
                    </span>
                    <span className="span-4 text"> 13kg</span>
                  </div>
                  <p className="catch-area-st-georges-channel">
                    <span className="poppins-semi-bold-tundora-13px">
                      Catch area:{" "}
                    </span>
                    <span className="span-4 text">{`St George’s Channel`}</span>
                  </p>
                  <p className="catch-method-by-net">
                    <span className="poppins-semi-bold-tundora-13px">
                      Catch method:{" "}
                    </span>
                    <span className="span-4 text">By net</span>
                  </p>
                  <p className="name-market-operator-john-klopper">
                    <span className="poppins-semi-bold-tundora-13px">
                      Name market operator:{" "}
                    </span>
                    <span className="span-4 text">John Klopper</span>
                  </p>
                  <p className="food-standart-approval-566-kd0993">
                    <span className="poppins-semi-bold-tundora-13px">
                      Food standart approval #:{" "}
                    </span>
                    <span className="span-4 text">566KD0993</span>
                  </p>
                  <div className="contry-origin-wales">
                    <span className="poppins-semi-bold-tundora-13px">
                      Country origin:{" "}
                    </span>
                    <span className="span-4 text">Wales</span>
                  </div>
                  <p className="contry-of-destination-france">
                    <span className="poppins-semi-bold-tundora-13px">
                      Country of destination:{" "}
                    </span>
                    <span className="span-4 text">France</span>
                  </p>
                  <p className="fish-latin-name-bo">
                    <span className="poppins-semi-bold-tundora-13px">
                      Fish latin name:{" "}
                    </span>
                    <span className="span-4 text">(Pollachius pollachius)</span>
                  </p>
                  <p className="place-and-time-of-lo">
                    <span className="poppins-semi-bold-tundora-13px">
                      Place and time of loading:{" "}
                    </span>
                    <br />
                    <span className="span-4 text span-newline">
                      Wales, Porthcawl 10/06/2022 05:03:06
                    </span>
                  </p>
                  <p className="place-and-time-desti">
                    <span className="poppins-semi-bold-tundora-13px">
                      Place and time destination:{" "}
                    </span>
                    <br />
                    <span className="span-4 text span-newline">
                      France, Tourlaville 16/11/2022 13:15:08
                    </span>
                  </p>
                  <p className="competent-authority-welsh-porth-police">
                    <span className="poppins-semi-bold-tundora-13px">
                      Competent authority:{" "}
                    </span>
                    <span className="span-4 text">Welsh Porth Police</span>
                  </p>
                  <div className="health-certificate-5518-hb830">
                    <span className="poppins-semi-bold-tundora-13px">
                      Health certificate:{" "}
                    </span>
                    <span className="span-4 text">5518HB830</span>
                  </div>
                  <p className="authority-date-sig">
                    <span className="poppins-semi-bold-tundora-13px">
                      Authority date & signature:{" "}
                    </span>
                    <br />
                    <span className="span-4 text span-newline">
                      Sammuel L. 13/11/2022
                    </span>
                  </p>
                  <div className="iso-code-w4-d87-k3330">
                    <span className="poppins-semi-bold-tundora-13px">
                      ISO code:{" "}
                    </span>
                    <span className="span-4 text">W4D87K3330</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {openedReportDialog && <ReportIssue onClose={closeReportDialog} />}
    </>
  );
};

export default VerifiedData;
