import React from "react";
import { motion } from "framer-motion";
import Group482 from "../Group482";
import Group488 from "../Group488";
import Group429 from "../Group429";
import Group437 from "../Group437";
import Group428 from "../Group428";
import "./transport-doc-dialog.css";

const TransportDocDialog = ({ onSave, onCancel }) => {
  return (
    <div className="center-dialog">
      <img className="group-408-10" src="/img/group-408-9@2x.svg" />
      <div className="transport-doc-10 poppins-semi-bold-astronaut-17-5px">
        Transport Doc
      </div>
      <div className="devante-ltd-4 text">Devante Ltd</div>
      <div className="overlay"></div>
      <div className="overlap-group12-10">
        <div className="rectangle-135-6"></div>
        <Group482 className="group-432">Client name:</Group482>
        <Group488 className="group-444">Country origin:</Group488>
        <Group488 className="group-445">Fish Latin name:</Group488>
        <Group488 className="group-446">Country of destination:</Group488>
        <Group488 className="group-447">Place & time of loading:</Group488>
        <Group488 className="group-448">Place & time destination:</Group488>
        <Group429 className="group-429-6" onClick={onCancel} />
        <div className="overlap-group-94">
          <Group482 className="group-43-2">Fishery name:</Group482>
          <div className="name-27 text">James Miles</div>
        </div>
        <Group482 className="group-431-1">Transporter name:</Group482>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          className="name-28 text"
        >
          Miles Fast Ltd
        </motion.div>
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          className="wales-porthcawl-10112022-050306 text"
        >
          Wales, Porthcawl 10/06/2022 05:03:06
        </motion.p>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          className="place-22 text"
        >
          Wales
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          className="france-tourlaville-16112022 text"
        >
          France, Tourlaville 16/11/2022
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          className="place-23 text"
        >
          France
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          className="bollachius-pollachius-2"
        >
          Pollachius pollachius
        </motion.div>
        <div className="overlap-group1-34">
          <Group488 className="group-431">Food standart approval:</Group488>
          <div className="x26-dgklg78-2 poppins-semi-bold-white-13px">
            <span className="poppins-semi-bold-cod-gray-13px"></span>
            <motion.span
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.8 }}
              className="span1-23 text"
            >
              #26DGKLG78
            </motion.span>
          </div>
        </div>
        <div className="overlap-group2-31">
          <Group482 className="group-4-3">Fish species:</Group482>
          <div className="pollack-4 text">Pollack</div>
        </div>
        <div className="overlap-group3-11 text">
          <Group437 totalWeight="Box Weight:" className="group-435-1" />
          <div className="x13kg-3">{`13kg`}</div>
          <div className="number-34">1</div>
        </div>
        <Group428 className="group-428-2" onSave={onSave} onCancel={onCancel} />
        <img className="group-481" src="/img/group-481@2x.svg" />
        <div className="transport-doc-11 poppins-semi-bold-astronaut-17-5px">
          Transport Doc
        </div>
        <div className="devante-ltd-5 text">Devante Ltd</div>
      </div>
    </div>
  );
};

export default TransportDocDialog;
