import React from "react";
import { motion } from "framer-motion";
import "./Frame16.css";

function Frame16(props) {
  const { className } = props;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className={`frame-1-16 ${className || ""}`}
    >
      <div className="overlap-group2-35">
        <img className="image-6-1" src="/img/image-6-16@1x.png" />
        <div className="chart-name-51 poppins-bold-white-16px">Map</div>
        <div className="ellipse-44-13 border-1px-zest"></div>
      </div>
    </motion.div>
  );
}

export default Frame16;
