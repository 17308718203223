import React from "react";
import Content from "../Content";
import "./TabLineItem2.css";

function TabLineItem2(props) {
  const { contentProps } = props;

  return (
    <div className="tab-line-item-20">
      <Content>{contentProps.children}</Content>
      <div className="border-bottom-2px"></div>
    </div>
  );
}

export default TabLineItem2;
