import React from "react";
import "./Group482.css";

function Group482(props) {
  const { children, className } = props;

  return (
    <div className={`group-4-3-1 ${className || ""}`}>
      <div className="name-market-operator poppins-semi-bold-mine-shaft-13px">{children}</div>
      <div className="rectangle-1-3 border-1px-mercury"></div>
    </div>
  );
}

export default Group482;
