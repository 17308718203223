import React from "react";
import "./Group433.css";

function Group433(props) {
  const { className } = props;

  return (
    <div className={`group-433 ${className || ""}`}>
      <div className="flex-col-10">
        <div className="pnl poppins-semi-bold-mine-shaft-13px">PNL:</div>
        <div className="rectangle-14 border-1px-mercury"></div>
      </div>
      <div className="flex-col-11">
        <div className="boat-name poppins-semi-bold-mine-shaft-13px">Boat name:</div>
        <div className="rectangle-14 border-1px-mercury"></div>
      </div>
    </div>
  );
}

export default Group433;
