import React from "react";
import "./CardHeader.css";

function CardHeader() {
  return (
    <div className="card-header">
      <div className="chart-name-1 poppins-bold-mirage-16px"></div>
      <div className="icon-container"></div>
    </div>
  );
}

export default CardHeader;
