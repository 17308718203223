import React from "react";
import PillsContent from "../PillsContent";
import "./PillsItem2.css";

function PillsItem2(props) {
  const { pillsContentProps } = props;

  return (
    <div className="pills-item-20">
      <PillsContent
        us={pillsContentProps.us}
        className={pillsContentProps.className}
        addonProps={pillsContentProps.addonProps}
      />
    </div>
  );
}

export default PillsItem2;
