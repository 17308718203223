import React from "react";
import { motion } from "framer-motion";
import "./Frame13.css";

function Frame13(props) {
  const { className } = props;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className={`frame-1-6 ${className || ""}`}
    >
      <div className="chart-name-31 poppins-bold-white-16px">Map</div>
      <div className="overlap-group2-23">
        <div className="ellipse-44-3"></div>
      </div>
    </motion.div>
  );
}

export default Frame13;
