import React from "react";
import "./Group437.css";

function Group437(props) {
  const { totalWeight, className } = props;

  return (
    <div className={`group-437 ${className || ""}`}>
      <div className="flex-col-34">
        <div className="address poppins-semi-bold-mine-shaft-13px"># of boxes:</div>
        <div className="rectangle-143 border-1px-mercury"></div>
      </div>
      <div className="flex-col-35">
        <div className="total-weight poppins-semi-bold-mine-shaft-13px">{totalWeight}</div>
        <div className="rectangle-140 border-1px-mercury"></div>
      </div>
    </div>
  );
}

export default Group437;
