import React from "react";
import PillsContent from "../PillsContent";
import "./PillsItem.css";

function PillsItem(props) {
  const { pillsContentProps } = props;

  return (
    <div className="pills-item border-1px-azure-radiance">
      <PillsContent us={pillsContentProps.us} addonProps={pillsContentProps.addonProps} />
    </div>
  );
}

export default PillsItem;
