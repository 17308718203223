import React from "react";
import "./Group428.css";

function Group428(props) {
  const { className, onSave, onCancel } = props;

  return (
    <div className={`group-428 ${className || ""}`}>
      <div
        className="overlap-group29 border-2px-guardsman-red-2"
        onClick={onCancel}
      >
        <div className="cancel-18 poppins-semi-bold-guardsman-red-15px">
          Cancel
        </div>
      </div>
      <div className="overlap-group30" onClick={onSave}>
        <div className="save-6 poppins-semi-bold-white-15px">Save</div>
      </div>
    </div>
  );
}

export default Group428;
