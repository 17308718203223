import React from "react";
import { motion } from "framer-motion";
import Group429 from "../Group429";
import Group428 from "../Group428";
import Group433 from "../Group433";
import Group482 from "../Group482";
import Group438 from "../Group438";
import Group437 from "../Group437";
import Group457 from "../Group457";
import "./catch-certificate-dialog.css";

const CatchCertificateDialog = ({ onSave, onCancel }) => {
  return (
    <div className="center-dialog">
      <div className="overlay"></div>
      <div className="rectangle-135-4"></div>
      <div className="catch-certificate-2 poppins-semi-bold-astronaut-17-5px">
        Catch Certificate
      </div>
      <Group429 className="group-429-4" onClick={onCancel} />
      <Group428 className="group-428-1" onSave={onSave} onCancel={onCancel} />
      <img className="group-410-7" src="/img/group-410-6@2x.svg" />
      <div className="overlap-group14-4 text">
        <Group433 />
        <div className="phone-2">0409954</div>
        <div className="sealess-rock-2">Sealess Rock</div>
      </div>
      <div className="overlap-group15-2">
        <Group482 className="group-43">Fishery name:</Group482>
        <div className="name-18 text">James Miles</div>
      </div>
      <div className="overlap-group16-2">
        <Group438 />
        <div className="x13kg poppins-semi-bold-ocean-blue-pearl-13px">
          <span className="poppins-semi-bold-ocean-blue-pearl-13px"></span>
          <span className="span1-20 text">13kg</span>
        </div>
        <div className="grade-a-2 text">Grade A</div>
      </div>
      <div className="overlap-group17-2">
        <Group482 className="group-4-3">Fish species:</Group482>
        <div className="pollack-2 text">Pollack</div>
      </div>
      <div className="overlap-group18-2">
        <Group482 className="group-4-3">Client name:</Group482>
        <div className="devante-ltd-2 text">Devante Ltd</div>
      </div>
      <div className="overlap-group19-2 text">
        <Group437 totalWeight="Total Weight:" />
        <div className="x13kg-1">13kg</div>
        <div className="number-23">1</div>
      </div>
      <div className="group-459-x">
        <div className="flex-col-63">
          <div className="catch-certificate-3 poppins-medium-cod-gray-13px">
            Catch certificate:
          </div>
          <div className="overlap-group20-3 border-1px-mercury">
            <div className="k234-h5685-dfh9 text">#K234H5685DFH9</div>
          </div>
        </div>
        <div className="flex-col-64">
          <div className="date-of-inssuance poppins-medium-cod-gray-13px">
            Date of inssuance
          </div>
          <div className="overlap-group21-3 border-1px-mercury">
            <div className="date-39 text">12/11/2022</div>
          </div>
        </div>
      </div>
      <div className="overlap-group22-2">
        <Group482 className="group-4-3">Exporter name:</Group482>
        <div className="ice poppins-semi-bold-white-13px">
          <span className="poppins-semi-bold-tundora-13px"></span>
          <motion.span
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8 }}
            className="span1-21 text"
          >
            ICE.
          </motion.span>
        </div>
      </div>
      <div className="overlap-group23-2">
        <Group482 className="group-4-3">Validating entity:</Group482>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          className="porthcalw-sd text"
        >
          Porthcawl SD
        </motion.div>
      </div>
      <div className="overlap-group24-2">
        <Group482 className="group-4-3">Exporter adress:</Group482>
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          className="x2-4-dock-st-porthcawl-cf36-3-bl-uk text"
        >
          2-4 Dock St, Porthcawl CF36 3BL, UK
        </motion.p>
      </div>
      <div className="overlap-group25-2">
        <Group482 className="group-4-3">FAO area:</Group482>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          className="st-georges-channel-2 text"
        >{`St George’s Channel`}</motion.div>
      </div>
      <div className="overlap-group26-2">
        <Group482 className="group-4-3">Master of vessel:</Group482>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          className="name-19 text"
        >
          Bentley Grey
        </motion.div>
      </div>
      <div className="overlap-group27-2">
        <Group482 className="group-4-3">Licence number:</Group482>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          className="x3205911-h3-s text"
        >
          3205911H3S
        </motion.div>
      </div>
      <Group457>Master Signature:</Group457>
      <motion.img
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8 }}
        className="chris_-hemsworth_-signature-1"
        src="/img/chris-hemsworth-signature-1@2x.png"
      />
    </div>
  );
};

export default CatchCertificateDialog;
