import React from "react";
import clsx from "clsx";
import "./Group4072.css";

function Group4072(props) {
  const { className, onClick, page } = props;

  return (
    <div
      className={clsx(
        `group-407-14 ${className || ""}`,
        page === 2 && "rotate-arrow-icon"
      )}
      onClick={onClick}
    >
      <img className="vector-4-10" src="/img/vector-4@2x.svg" />
    </div>
  );
}

export default Group4072;
