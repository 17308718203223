import React from "react";
import Addon from "../Addon";
import "./PillsContent.css";

function PillsContent(props) {
  const { us, className, addonProps } = props;

  return (
    <div className={`pills-content ${className || ""}`}>
      <Addon flagProps={addonProps.flagProps} />
      <div className="us poppins-medium-mirage-14px">{us}</div>
    </div>
  );
}

export default PillsContent;
