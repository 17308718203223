import React from "react";
import { NavLink } from "react-router-dom";
import Logo from "../Logo";
import "./header.css";

function Header() {
  return (
    <header>
      <div className="navigation-menu">
        <NavLink to="/dashboard" activeClassName="active-menu-button">
          <div className="dashboard-menu-button valign-text-middle poppins-bold-white-14px">
            DASHBOARD
          </div>
        </NavLink>
        <NavLink to="/passport" activeClassName="active-menu-button">
          <div className="passport-menu-button valign-text-middle t1">
            PASSPORT
          </div>
        </NavLink>
        <div className="audit-logs-menu-button valign-text-middle t1">
          AUDIT LOGS
        </div>
      </div>
      <Logo />
    </header>
  );
}

export default Header;
