import React from "react";
import "./Group381.css";

function Group381(props) {
  const { className } = props;

  return (
    <div className={`group-381 ${className || ""}`}>
      <div className="fishing-28 poppins-semi-bold-astronaut-17-6px">Fishing</div>
      <img className="group-73" src="/img/group-73@2x.svg" />
    </div>
  );
}

export default Group381;
