import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import HeaderVerify from "../HeaderVerify";
import VerifiableFileData from "../VerifiableFileData";
import VerifiedData from "../VerifiedData";
import "./verify-page.css";

const VerifyPage = (props) => {
  const {
    showFishing,
    showLanding,
    showWeighting,
    showAuctioning,
    showTransportation,
    showCustomClearance,
    issuedDate,
    downloadFile,
    proofFile,
    previewFilePath,
  } = props;
  const [isVerified, setVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onVerify = () => {
    setIsLoading(true);
    const t = setTimeout(() => {
      setVerified(true);
      setIsLoading(false);
      clearTimeout(t);
    }, 1000);
  };

  useEffect(() => {
    if (isVerified) {
      document
        .getElementById("passport-verified-data")
        .scrollIntoView({ behavior: "smooth" });
    }
  }, [isVerified]);

  return (
    <>
      <HeaderVerify />
      <div className="verify-page-content">
        <div className="verify-page">
          <div className="verify-preview-container">
            <div className="verify-preview-inner-container">
              <motion.img
                src={previewFilePath}
                className="verify-preview-image"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
              />
              <motion.a
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                href={downloadFile.path}
                download={downloadFile.filename}
                className="download-button-container border-1px-diesel"
              >
                <img
                  className="download-icon"
                  src="/img/files-download@2x.svg"
                />
                <span className="download-button-text opensans-normal-diesel-13px">
                  DOWNLOAD
                </span>
              </motion.a>
            </div>
          </div>
          <div id="passport-verified-data" />
          <VerifiableFileData
            isVerified={isVerified}
            onVerify={onVerify}
            isLoading={isLoading}
            issuedDate={issuedDate}
            proofFile={proofFile}
            downloadFile={downloadFile}
            viewingFishingLanding={showFishing && showLanding}
            viewingWeighting={showWeighting}
            viewingAuctioning={showAuctioning}
            viewingTransportation={showTransportation}
            viewingCustomClearance={showCustomClearance}
          />
          {isVerified && (
            <motion.div
              className="data-tabs"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
            >
              <VerifiedData
                showFishing={showFishing}
                showLanding={showLanding}
                showWeighting={showWeighting}
                showAuctioning={showAuctioning}
                showTransportation={showTransportation}
                showCustomClearance={showCustomClearance}
              />
            </motion.div>
          )}
        </div>
      </div>
      {isLoading && <div className="overlay" />}
    </>
  );
};

export default VerifyPage;
