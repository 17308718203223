import { action, createStore } from "easy-peasy";

const store = createStore({
  submittedVesselData: false,
  submittedLotData: false,
  submittedSalesNoteData: false,
  submittedTransportDocData: false,
  submittedCatchCertificateData: false,
  submittedHealthCertificateData: false,

  setSubmittedVesselData: action((state) => {
    state.submittedVesselData = true;
  }),
  setSubmittedLotData: action((state) => {
    state.submittedLotData = true;
  }),
  setSubmittedSalesNoteData: action((state) => {
    state.submittedSalesNoteData = true;
  }),
  setSubmittedTransportDocData: action((state) => {
    state.submittedTransportDocData = true;
  }),
  setSubmittedCatchCertificateData: action((state) => {
    state.submittedCatchCertificateData = true;
  }),
  setSubmittedHealthCertificateData: action((state) => {
    state.submittedHealthCertificateData = true;
  }),
});

export default store;
