import React from "react";
import CardHeader from "../CardHeader";
import Pills from "../Pills";
import TabLine from "../TabLine";
import "./Card.css";

function Card(props) {
  const { pillsProps } = props;

  return (
    <div className="card">
      <CardHeader />
      <div className="card-body">
        <Pills
          pillsItemProps={pillsProps.pillsItemProps}
          pillsItem2Props={pillsProps.pillsItem2Props}
          pillsItem3Props={pillsProps.pillsItem3Props}
        />
        <TabLine />
      </div>
    </div>
  );
}

export default Card;
