import React from "react";
import "./auctioning-step-data.css";

const AuctioningStepData = () => {
  return (
    <div className="group-396 poppins-semi-bold-white-13px">
      <div className="group-374">
        <div className="auctioning-2 poppins-semi-bold-astronaut-15px">
          Auctioning
        </div>
        <img className="auctioning-icon" src="/img/auctioning-icon.svg" />
      </div>
      <p className="client-name-devante-ltd-xx">
        <span className="poppins-semi-bold-tundora-13px">
          Client name:&nbsp;&nbsp;
        </span>
        <span className="span1-8 text">Devante Ltd</span>
      </p>
      <p className="group-396-item">
        <span className="poppins-semi-bold-tundora-13px">
          Fish species:&nbsp;&nbsp;{" "}
        </span>
        <span className="span1-8 text">Pollack</span>
      </p>
      <div className="group-396-item">
        <span className="poppins-semi-bold-tundora-13px">Lot grade: </span>
        <span className="span1-8 text">Grade A</span>
      </div>
      <div className="group-396-item">
        <span className="poppins-semi-bold-tundora-13px">Lot weight: </span>
        <span className="span1-8 text">457kg</span>
      </div>
      <div className="group-396-item">
        <span className="poppins-semi-bold-tundora-13px">
          Total weight:&nbsp;&nbsp;
        </span>
        <span className="span1-8 text">457kg</span>
      </div>
      <p className="group-396-item">
        <span className="poppins-semi-bold-tundora-13px">
          # of boxes:&nbsp;&nbsp;
        </span>
        <span className="span1-8 text">45D5GHKA</span>
      </p>
      <p className="group-396-item">
        <span className="poppins-semi-bold-tundora-13px">
          Fishery name:&nbsp;&nbsp;
        </span>
        <span className="span1-8 text">James Miles</span>
      </p>
    </div>
  );
};

export default AuctioningStepData;
