import React from "react";
import "./fishing-step-data.css";

const FishingStepData = () => {
  return (
    <div className="section-data">
      <div className="group-381-2-xx">
        <div className="fishing-32 poppins-semi-bold-astronaut-15px">
          Fishing
        </div>
        <img className="fishing-icon" src="/img/group-73-4@2x.svg" />
      </div>
      <div className="group-37">
        <div className="course-2 poppins-semi-bold-tundora-13px">Course:</div>
        <div className="route-slooq-1 text">ROUTE SLOOQ</div>
      </div>
      <div className="group-37-7">
        <div className="date-21 poppins-semi-bold-tundora-13px">Date:</div>
        <div className="date-22 text">10/06/2022</div>
      </div>
      <div className="group-379">
        <div className="time-2 poppins-semi-bold-tundora-13px">Time:</div>
        <div className="text-8-1 text">06:58:00</div>
      </div>
    </div>
  );
};

export default FishingStepData;
