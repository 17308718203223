import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { useStoreState, useStoreActions } from "easy-peasy";
import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";
import Header from "../Header";
import Frame1 from "../Frame1";
import Frame14 from "../Frame14";
import Frame16 from "../Frame16";
import Frame15 from "../Frame15";
import Frame13 from "../Frame13";
import store from "../../store";
import { upperFirst } from "lodash";
import Tasks from "../Tasks";
import AuditLogs from "../AuditLogs";
import ClientStatistic from "../ClientStatistic";
import FishingDialog from "../FishingDialog";
import LotDataDialog from "../LotDataDialog";
import SalesNoteDialog from "../SalesNoteDialog";
import TransportDocDialog from "../TransportDocDialog";
import CatchCertificateDialog from "../CatchCertificateDialog";
import HealthCertificateDialog from "../HealthCertificateDialog";
import "./dashboard-page.css";

const convertStepToNumber = ({
  submittedVesselData,
  submittedLotData,
  submittedSalesNoteData,
  submittedTransportDocData,
  submittedCatchCertificateData,
  submittedHealthCertificateData,
}) => {
  let step = 1;
  if (submittedVesselData) {
    step = 2;
  }
  if (submittedLotData) {
    step = 3;
  }
  if (submittedSalesNoteData) {
    step = 4;
  }
  if (submittedTransportDocData) {
    step = 5;
  }
  if (submittedCatchCertificateData) {
    step = 6;
  }
  if (submittedHealthCertificateData) {
    step = 7;
  }
  return step;
};

const convertNumberToStep = (step) => {
  switch (step) {
    case 2:
      return "submittedVesselData";
    case 3:
      return "submittedLotData";
    case 4:
      return "submittedSalesNoteData";
    case 5:
      return "submittedTransportDocData";
    case 6:
      return "submittedCatchCertificateData";
    case 7:
      return "submittedHealthCertificateData";
    default:
      return 1;
  }
};

function Dashboard(props) {
  const { frame1Props } = props;
  const state = useStoreState((st) => st);
  const actions = useStoreActions((act) => act);
  const [step, setStep] = useState(convertStepToNumber(state));
  const [showDialog, setShowDialog] = useState(false);

  const onSave = () => {
    setStep(step + 1);
    const stateStep = convertNumberToStep(step + 1);
    actions[`set${upperFirst(stateStep)}`]();
    setShowDialog(false);
  };

  const addData = () => {
    setShowDialog(true);
  };

  const onCancel = () => {
    setShowDialog(false);
  };

  useEffect(() => {
    if (step === 7) {
      document
        .getElementById("steps-widget")
        .scroll({ left: 1000, top: 0, behavior: "smooth" });
    }
  }, [step]);

  return (
    <>
      <Header />
      <div className="portal-page-content">
        <div className="first-row-widgets">
          {step === 1 && <Frame1 className={frame1Props.className} />}
          {step === 2 && <Frame16 />}
          {step === 3 && <Frame15 />}
          {step === 4 && <Frame14 />}
          {step === 5 && <Frame13 />}
          {(step === 6 || step === 7) && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="frame-1-15"
            >
              <div className="chart-name-47 poppins-bold-white-16px">Map</div>
              <div
                className="overlap-group10-9"
                style={{ backgroundImage: `url(/img/image-6-14@1x.png)` }}
              >
                <div className="ellipse-44-12"></div>
              </div>
            </motion.div>
          )}
          <ClientStatistic {...props} />
        </div>
        <div
          id="steps-widget"
          className="steps-widget poppins-semi-bold-white-16px"
        >
          <div
            onClick={(step === 1 && addData) || null}
            className={clsx("step-1-active", step === 1 && "current-step")}
          >
            <div className="vessel-data poppins-semi-bold-white-16px">
              Vessel Data
            </div>
            <div className="group-container-107">
              <div className="overlap-group-162">
                <div className="poppins-bold-white-9px">1</div>
              </div>
              <img className="group-407-27" src="/img/group-407-17@2x.svg" />
            </div>
          </div>
          <div
            onClick={(step === 2 && addData) || null}
            className={clsx(
              "step-2",
              step >= 2 && "step-2-active",
              step === 2 && "current-step"
            )}
          >
            <div className="lot-data poppins-semi-bold-white-16px">
              Lot Data
            </div>
            <div className="group-container-108">
              <div className="overlap-group-163">
                <div className="number-2-fixed poppins-bold-white-9px">2</div>
              </div>
              <img className="group-412-18" src="/img/group-412-18@2x.svg" />
            </div>
          </div>
          <div
            onClick={(step === 3 && addData) || null}
            className={clsx(
              "step-3",
              step >= 3 && "step-3-active",
              step === 3 && "current-step"
            )}
          >
            <div className="sales-note poppins-semi-bold-white-16px">
              Sales Note
            </div>
            <div className="group-container-107">
              <div className="overlap-group-162">
                <div className="number-3-fixed poppins-bold-white-9px">3</div>
              </div>
              <img className="group-407-27" src="/img/group-409-25@2x.svg" />
            </div>
          </div>
          <div
            onClick={(step === 4 && addData) || null}
            className={clsx(
              "step-4",
              step >= 4 && "step-4-active",
              step === 4 && "current-step"
            )}
          >
            <div className="transport-doc">Transport Doc</div>
            <div className="group-container-109">
              <div className="overlap-group-165">
                <div className="number-4-fixed poppins-bold-white-9px">4</div>
              </div>
              <img className="group-408-19" src="/img/group-408-19@2x.svg" />
            </div>
          </div>
          <div
            onClick={(step === 5 && addData) || null}
            className={clsx(
              "step-5",
              step >= 5 && "step-5-active",
              step === 5 && "current-step"
            )}
          >
            <div className="catch-certificate">Catch Certificate</div>
            <div className="group-container-110">
              <div className="overlap-group-166">
                <div className="number-5-fixed poppins-bold-white-9px">5</div>
              </div>
              <img className="group-410-20" src="/img/group-410@2x.svg" />
            </div>
          </div>
          <div
            onClick={(step === 6 && addData) || null}
            className={clsx(
              "step-6",
              step >= 6 && "step-6-active",
              step === 6 && "current-step"
            )}
          >
            <div className="health-certificate">Health Certificate</div>
            <div className="group-container-111">
              <div className="overlap-group-167">
                <div className="poppins-bold-white-9px">6</div>
              </div>
              <img className="group-411-21" src="/img/group-411-21@2x.svg" />
            </div>
          </div>
          {step === 7 && (
            <NavLink to="/passport" className="overlap-group7-25">
              <div className="go-to-passport-1 poppins-semi-bold-white-15px">
                Go to Passport
              </div>
            </NavLink>
          )}
        </div>
        <div className="last-row-widgets">
          <AuditLogs />
          <Tasks />
        </div>
        {showDialog && step === 1 && (
          <FishingDialog onSave={onSave} onCancel={onCancel} />
        )}
        {showDialog && step === 2 && (
          <LotDataDialog onSave={onSave} onCancel={onCancel} />
        )}
        {showDialog && step === 3 && (
          <SalesNoteDialog onSave={onSave} onCancel={onCancel} />
        )}
        {showDialog && step === 4 && (
          <TransportDocDialog onSave={onSave} onCancel={onCancel} />
        )}
        {showDialog && step === 5 && (
          <CatchCertificateDialog onSave={onSave} onCancel={onCancel} />
        )}
        {showDialog && step === 6 && (
          <HealthCertificateDialog onSave={onSave} onCancel={onCancel} />
        )}
      </div>
    </>
  );
}

export default Dashboard;
