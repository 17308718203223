import "./App.css";
import React from "react";
import { Switch, BrowserRouter, Route, Redirect } from "react-router-dom";
import { StoreProvider } from "easy-peasy";
import store from "./store";
import DashboardPage from "./components/DashboardPage";
import PassportPage from "./components/PassportPage";
import VerifyPage from "./components/VerifyPage";

function App() {
  return (
    <StoreProvider store={store}>
      <BrowserRouter>
        <Switch>
          <Route path="/dashboard">
            <DashboardPage {...dashboardData} />
          </Route>
          <Route path="/passport">
            <PassportPage />
          </Route>
          <Route path="/verify-fishing-landing">
            <VerifyPage
              showFishing
              showLanding
              issuedDate="June 30, 2022"
              downloadFile={{
                path: "/downloads/passport_landing_fishing.pdf",
                filename: "passport134B.pdf",
              }}
              proofFile={{
                path: "/downloads/fishing_landing_proof.json",
                filename: "passport134B_proof.json",
              }}
              previewFilePath="/img/preview_fishing_landing.png"
            />
          </Route>
          <Route path="/verify-weighting">
            <VerifyPage
              showFishing
              showLanding
              showWeighting
              issuedDate="June 30, 2022"
              downloadFile={{
                path: "/downloads/passport_weighting.pdf",
                filename: "passport134B.pdf",
              }}
              proofFile={{
                path: "/downloads/weighting_proof.json",
                filename: "passport134B_proof.json",
              }}
              previewFilePath="/img/preview_weighting.png"
            />
          </Route>
          <Route path="/verify-auctioning">
            <VerifyPage
              showFishing
              showLanding
              showWeighting
              showAuctioning
              issuedDate="June 30, 2022"
              downloadFile={{
                path: "/downloads/passport_auctioning.pdf",
                filename: "passport134B.pdf",
              }}
              proofFile={{
                path: "/downloads/auctioning_proof.json",
                filename: "passport134B_proof.json",
              }}
              previewFilePath="/img/preview_auctioning.png"
            />
          </Route>
          <Route path="/verify-transportation">
            <VerifyPage
              showFishing
              showLanding
              showWeighting
              showAuctioning
              showTransportation
              issuedDate="June 30, 2022"
              downloadFile={{
                path: "/downloads/passport_transportation.pdf",
                filename: "passport134B.pdf",
              }}
              proofFile={{
                path: "/downloads/transportation_proof.json",
                filename: "passport134B_proof.json",
              }}
              previewFilePath="/img/preview_transportation.png"
            />
          </Route>
          <Route path="/verify-custom-clearance">
            <VerifyPage
              showFishing
              showLanding
              showWeighting
              showAuctioning
              showTransportation
              showCustomClearance
              issuedDate="June 30, 2022"
              downloadFile={{
                path: "/downloads/passport_custom_clearance.pdf",
                filename: "passport134B.pdf",
              }}
              proofFile={{
                path: "/downloads/custom_clearance_proof.json",
                filename: "passport134B_proof.json",
              }}
              previewFilePath="/img/preview_custom_clearance.png"
            />
          </Route>
          <Redirect from="*" to="/dashboard" />
        </Switch>
      </BrowserRouter>
    </StoreProvider>
  );
}

export default App;

const frame17Data = {
  className: "frame-1-2",
};

const flag8Data = {
  src: "/img/flag-1@2x.png",
};

const addon8Data = {
  flagProps: flag8Data,
};

const pillsItem23Data = {
  pillsContentProps: {
    us: "UK",
    className: "pills-content-7",
    addonProps: addon8Data,
  },
};

const flag9Data = {
  src: "/img/flag-2@2x.png",
};

const addon9Data = {
  flagProps: flag9Data,
};

const pillsItem33Data = {
  pillsContentProps: {
    us: "France",
    className: "pills-content-8",
    addonProps: addon9Data,
  },
};

const flag7Data = {
  src: "/img/flag@2x.png",
};

const addon7Data = {
  flagProps: flag7Data,
};

const pillsItem5Data = {
  pillsContentProps: { us: "NL", addonProps: addon7Data },
};

const pills3Data = {
  pillsItemProps: pillsItem5Data,
  pillsItem2Props: pillsItem23Data,
  pillsItem3Props: pillsItem33Data,
};

const card3Data = {
  pillsProps: pills3Data,
};

const tabLineItem23Data = {
  contentProps: { children: "1M" },
};

const tabLineItem35Data = {
  contentProps: { children: "3M" },
};

const content11Data = {
  children: "6M",
};

const tabLineItem36Data = {
  className: "tab-line-item-45",
  contentProps: content11Data,
};

const content12Data = {
  children: "All time",
  className: "content-22",
};

const dashboardData = {
  frame1Props: frame17Data,
  cardProps: card3Data,
  tabLineItem2Props: tabLineItem23Data,
  tabLineItem31Props: tabLineItem35Data,
  tabLineItem32Props: tabLineItem36Data,
  contentProps: content12Data,
};
