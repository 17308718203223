import React from "react";
import DigitalPassportInfo from "../DigitalPassportInfo";
import DigitalPassportMap from "../DigitalPassportMap";
import DigitalPassportStepData from "../DigitalPassportStepData";
import "./digital-passport.css";

const DigitalPassport = (props) => {
  return (
    <div className="digital-passport">
      <div className="flex-col-52">
        <h1 className="title poppins-bold-bay-of-many-24px">
          Digital Passport: #134B
        </h1>
        <DigitalPassportInfo {...props} />
      </div>
      <div className="overlap-group13-4">
        <DigitalPassportMap {...props} />
        <DigitalPassportStepData {...props} />
      </div>
    </div>
  );
};

export default DigitalPassport;
