import React from "react";
import PillsContent from "../PillsContent";
import "./PillsItem3.css";

function PillsItem3(props) {
  const { pillsContentProps } = props;

  return (
    <div className="pills-item-40">
      <PillsContent
        us={pillsContentProps.us}
        className={pillsContentProps.className}
        addonProps={pillsContentProps.addonProps}
      />
    </div>
  );
}

export default PillsItem3;
