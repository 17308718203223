import React from "react";
import Content from "../Content";
import "./TabLineItem3.css";

function TabLineItem3(props) {
  const { className, contentProps } = props;

  return (
    <div className={`tab-line-item-40 ${className || ""}`}>
      <Content>{contentProps.children}</Content>
    </div>
  );
}

export default TabLineItem3;
