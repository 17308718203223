import React from "react";
import "./report-issue.css";

const ReportIssue = ({ onClose }) => {
  return (
    <>
      <div class="overlay" />
      <div className="group-454">
        <div className="overlap-group11-1x">
          <div className="rectangle-447"></div>
          <div className="report-an-issue">Report an Issue</div>
          <div className="rectangle-448"></div>
          <div className="rectangle-449"></div>
          <div className="rectangle-450"></div>
          <div className="place-26">Send</div>
          <div className="subject poppins-medium-gravel-14px">Subject</div>
          <div className="write-a-message poppins-medium-gravel-14px">
            Write a message:
          </div>
          <img className="x4-1" src="/img/4@2x.svg" />
          <div className="group-429-1">
            <img
              className="cancel"
              src="/img/cancel@2x.svg"
              onClick={onClose}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportIssue;
