import React from "react";
import TabLineItem from "../TabLineItem";
import "./TabLine.css";

function TabLine() {
  return (
    <div className="tab-line">
      <TabLineItem />
    </div>
  );
}

export default TabLine;
