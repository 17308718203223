import React from "react";
import { motion } from "framer-motion";
import Group488 from "../Group488";
import Group482 from "../Group482";
import Group429 from "../Group429";
import Group435 from "../Group435";
import Group433 from "../Group433";
import Group437 from "../Group437";
import Group438 from "../Group438";
import Group428 from "../Group428";
import "./health-certificate-dialog.css";

const HealthCertificateDialog = ({ onSave, onCancel }) => {
  return (
    <div className="center-dialog">
      <div className="overlay"></div>
      <div className="health-certificate-2 poppins-semi-bold-astronaut-17-5px">
        Health Certificate
      </div>
      <img className="group-411-2" src="/img/group-411-1@2x.svg" />
      <div className="rectangle-135-1"></div>
      <div className="overlap-group11-1">
        <Group488>Place & time of loading:</Group488>
        <p className="x112022 text">Wales, Porthcawl 10/06/2022 05:03:06</p>
      </div>
      <div className="overlap-group12-1">
        <Group488 className="group-4">Country origin:</Group488>
        <div className="place-2 text">Wales</div>
      </div>
      <div className="overlap-group13-1">
        <Group488 className="group-4">Place & time destination:</Group488>
        <div className="x112022 text">France, Tourlaville 16/11/2022</div>
      </div>
      <div className="overlap-group14-1">
        <Group488 className="group-4">Competent authority:</Group488>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          className="welsh-porth-police text"
        >
          Welsh Porth Police
        </motion.div>
      </div>
      <div className="overlap-group15">
        <Group488 className="group-4">Health certificate:</Group488>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          className="x5518-hb830 text"
        >
          5518HB830
        </motion.div>
      </div>
      <div className="overlap-group16">
        <Group488 className="group-4">Authority date & signature:</Group488>
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          className="sammuel-l-13112022 text"
        >
          Sammuel L. 13/11/2022
        </motion.p>
      </div>
      <div className="overlap-group17">
        <Group488 className="group-4">ISO code:</Group488>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          className="w4-d87-k3330 text"
        >
          W4D87K3330
        </motion.div>
      </div>
      <div className="overlap-group18">
        <Group488 className="group-4">Country of destination:</Group488>
        <div className="place-3 text">France</div>
      </div>
      <Group429 className="group-429-1" onClick={onCancel} />
      <div className="health-certificate-3 poppins-semi-bold-astronaut-17-5px">
        Health Certificate
      </div>
      <img className="group-411-3" src="/img/group-411-2@2x.svg" />
      <div className="overlap-group19 text">
        <Group435 />
        <div className="st-georges-channel">{`St George’s Channel`}</div>
        <div className="by-net">By net</div>
      </div>
      <div className="overlap-group20-1">
        <Group482>Name market operator:</Group482>
        <div className="name-4 text">John Klopper</div>
      </div>
      <div className="overlap-group21-1 text">
        <Group433 />
        <div className="phone">0409954</div>
        <div className="sealess-rock">Sealess Rock</div>
      </div>
      <div className="overlap-group22">
        <Group482 className="group-4-3">Fishery name:</Group482>
        <div className="name-5 text">James Miles</div>
      </div>
      <div className="overlap-group23">
        <Group438 />
        <div className="x13-kg poppins-semi-bold-ocean-blue-pearl-13px">
          <span className="poppins-semi-bold-ocean-blue-pearl-13px"></span>
          <motion.span
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8 }}
            className="span1 text"
          >
            13Kg
          </motion.span>
        </div>
        <div className="grade-a text">Grade A</div>
      </div>
      <div className="overlap-group24">
        <Group482 className="group-4-3">Fish species:</Group482>
        <div className="pollack text">Pollack</div>
      </div>
      <div className="overlap-group25-1">
        <Group482 className="group-4-3">Client name:</Group482>
        <div className="devante-ltd text">Devante Ltd</div>
      </div>
      <div className="overlap-group26-1 text">
        <Group437 totalWeight="Total weight:" />
        <div className="x13-kg-1">13kg</div>
        <div className="number-6">1</div>
      </div>
      <div className="overlap-group27">
        <Group488 className="group-4">Food standart approval:</Group488>
        <div className="x26-dgklg78 poppins-semi-bold-white-13px">
          <span className="poppins-semi-bold-cod-gray-13px"></span>
          <motion.span
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8 }}
            className="span1 text"
          >
            #26DGKLG78
          </motion.span>
        </div>
      </div>
      <div className="overlap-group28">
        <Group488 className="group-4">Fish Latin name:</Group488>
        <div className="bollachius-pollachius poppins-light-ocean-blue-pearl-13px">
          Pollachius pollachius
        </div>
      </div>
      <Group428 onSave={onSave} onCancel={onCancel} />
    </div>
  );
};

export default HealthCertificateDialog;
