import React from "react";
import "./Flag.css";

function Flag(props) {
  const { src } = props;

  return (
    <div className="flag">
      <img className="flag-1" src={src} />
    </div>
  );
}

export default Flag;
