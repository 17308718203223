import React from "react";
import "./issuer-info.css";

const IssuerInfo = () => {
  return (
    <div className="issuer-info-container">
      <div className="issuer-avatar">
        <div className="ellipse-46"></div>
        <img className="vector-49" src="/img/vector-49@2x.svg" />
      </div>
      <div className="issuer-name">
        <div className="issuer-name-text_label valign-text-middle opensans-semi-bold-mine-shaft-18px">
          COLD FISH VERIFICATION
        </div>
      </div>
    </div>
  );
};

export default IssuerInfo;
