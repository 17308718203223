import React from "react";
import { motion } from "framer-motion";
import "./digital-passport-map.css";

const mapViews = {
  initial: "/img/image-6-4@1x.png",
  viewByStep: "/img/image-6-7@1x.png",
};

const DigitalPassportMap = (props) => {
  const {
    viewingFishing,
    viewingLanding,
    viewingWeighting,
    viewingAuctioning,
    viewingTransportation,
    viewingCustomClearance,
    submittedVesselData,
  } = props;

  if (viewingFishing) {
    return (
      <div className="overlap-group11-15">
        <div className="frame-2-2">
          <img
            className="passport-fishing-map-image"
            src={mapViews.viewByStep}
          />
        </div>
        <div className="ellipse-container-2">
          <div className="ellipse-7-2"></div>
          <div className="ellipse-8-2 border-2px-zest"></div>
        </div>
        <div className="group-410-18">
          <img className="group-73-8" src="/img/group-73-3@2x.svg" />
        </div>
      </div>
    );
  }
  if (viewingLanding) {
    return (
      <div className="overlap-group14-7">
        <div className="frame-2-4">
          <div
            className="passport-landing-map-image"
            style={{ backgroundImage: `url(${mapViews.viewByStep})` }}
          >
            <div className="group-428-5">
              <img className="group-424" src="/img/group-424@2x.svg" />
            </div>
          </div>
        </div>
        <div className="overlap-group7-19">
          <img className="vector-5-2" src="/img/vector-5-1@2x.svg" />
          <div className="ellipse-container-3">
            <div className="ellipse-7-3"></div>
            <div className="ellipse-8-3 border-2px-zest"></div>
          </div>
        </div>
      </div>
    );
  }
  if (viewingWeighting) {
    return (
      <div className="overlap-group14-8">
        <div className="frame-2-6">
          <div
            className="passport-weighting-map-image"
            style={{ backgroundImage: `url(${mapViews.viewByStep})` }}
          >
            <div className="group-425">
              <img className="group-75-6" src="/img/group-75-3@2x.svg" />
            </div>
          </div>
        </div>
        <div className="overlap-group7-20">
          <img className="vector-5-3" src="/img/vector-5-1@2x.svg" />
          <div className="ellipse-container-4">
            <div className="ellipse-7-4"></div>
            <div className="ellipse-8-4 border-2px-zest"></div>
          </div>
        </div>
      </div>
    );
  }
  if (viewingAuctioning) {
    return (
      <div className="overlap-group14-10">
        <div className="frame-2-10">
          <div
            className="passport-auctioning-map-image"
            style={{ backgroundImage: `url(${mapViews.viewByStep})` }}
          >
            <div className="group-423">
              <img className="group-417" src="/img/group-417@2x.svg" />
            </div>
          </div>
        </div>
        <div className="overlap-group7-23">
          <img className="vector-5-5" src="/img/vector-5-1@2x.svg" />
          <div className="ellipse-container-6">
            <div className="ellipse-7-6"></div>
            <div className="ellipse-8-6 border-2px-zest"></div>
          </div>
        </div>
      </div>
    );
  }
  if (viewingTransportation) {
    return (
      <div className="overlap-group12-20">
        <div className="frame-2-8">
          <div
            className="passport-transportation-map-image"
            style={{ backgroundImage: `url(${mapViews.viewByStep})` }}
          >
            <div className="group-426">
              <img className="group-77-6" src="/img/group-77-4@2x.svg" />
            </div>
          </div>
        </div>
        <div className="overlap-group7-21">
          <img className="vector-5-4" src="/img/vector-5-1@2x.svg" />
          <div className="ellipse-container-5">
            <div className="ellipse-7-5"></div>
            <div className="ellipse-8-5 border-2px-zest"></div>
          </div>
        </div>
      </div>
    );
  }
  if (viewingCustomClearance) {
    return (
      <div className="overlap-group13-21">
        <div className="frame-2-12">
          <div
            className="passport-custom-clearance-map-image"
            style={{ backgroundImage: `url(${mapViews.viewByStep})` }}
          >
            <div className="group-427-5">
              <img className="group-78-7" src="/img/group-78-7@2x.svg" />
            </div>
          </div>
        </div>
        <div className="overlap-group7-26">
          <img className="vector-5-6" src="/img/vector-5-1@2x.svg" />
          <div className="ellipse-container-7">
            <div className="ellipse-7-7"></div>
            <div className="ellipse-8-7 border-2px-zest"></div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <>
      <div className="frame-2">
        <motion.img
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="passport-initial-map-image"
          src={mapViews.initial}
        />
      </div>
      {submittedVesselData && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="ellipse-container"
        >
          <div className="ellipse-7"></div>
          <div className="ellipse-8 border-2px-zest"></div>
        </motion.div>
      )}
    </>
  );
};

export default DigitalPassportMap;
