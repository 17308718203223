import React from "react";
import "./Group436.css";

function Group436() {
  return (
    <div className="group-436">
      <div className="flex-col-93">
        <div className="lot-size poppins-semi-bold-mine-shaft-13px">Lot size:</div>
        <div className="rectangle-1-13 border-1px-mercury"></div>
      </div>
      <div className="flex-col-94">
        <div className="lot-grade-6 poppins-semi-bold-mine-shaft-13px">Lot grade:</div>
        <div className="rectangle-1-13 border-1px-mercury"></div>
      </div>
      <div className="flex-col-95">
        <div className="lot-weight-6 poppins-semi-bold-mine-shaft-13px">Lot weight:</div>
        <div className="rectangle-1-13 border-1px-mercury"></div>
      </div>
    </div>
  );
}

export default Group436;
