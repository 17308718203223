import React, { useState } from "react";
import { useStoreState } from "easy-peasy";
import Header from "../Header";
import DigitalPassport from "../DigitalPassport";

const PassportPage = () => {
  const state = useStoreState((st) => st);
  const [viewingFishing, setViewFishing] = useState(false);
  const [viewingLanding, setViewLanding] = useState(false);
  const [viewingWeighting, setViewWeighting] = useState(false);
  const [viewingAuctioning, setViewAuctioning] = useState(false);
  const [viewingTransportation, setViewTransportation] = useState(false);
  const [viewingCustomClearance, setViewCustomClearance] = useState(false);

  const viewFishingData = () => {
    setViewFishing(true);
    setViewLanding(false);
    setViewWeighting(false);
    setViewAuctioning(false);
    setViewTransportation(false);
    setViewCustomClearance(false);
  };

  const viewLandingData = () => {
    setViewFishing(false);
    setViewLanding(true);
    setViewWeighting(false);
    setViewAuctioning(false);
    setViewTransportation(false);
    setViewCustomClearance(false);
  };

  const viewWeightingData = () => {
    setViewFishing(false);
    setViewLanding(false);
    setViewWeighting(true);
    setViewAuctioning(false);
    setViewTransportation(false);
    setViewCustomClearance(false);
  };

  const viewAuctioningData = () => {
    setViewFishing(false);
    setViewLanding(false);
    setViewWeighting(false);
    setViewAuctioning(true);
    setViewTransportation(false);
    setViewCustomClearance(false);
  };

  const viewTransportationData = () => {
    setViewFishing(false);
    setViewLanding(false);
    setViewWeighting(false);
    setViewAuctioning(false);
    setViewTransportation(true);
    setViewCustomClearance(false);
  };

  const viewCustomClearanceData = () => {
    setViewFishing(false);
    setViewLanding(false);
    setViewWeighting(false);
    setViewAuctioning(false);
    setViewTransportation(false);
    setViewCustomClearance(true);
  };

  return (
    <>
      <Header />
      <div className="portal-page-content">
        <DigitalPassport
          viewingFishing={viewingFishing}
          viewingLanding={viewingLanding}
          viewingWeighting={viewingWeighting}
          viewingAuctioning={viewingAuctioning}
          viewingTransportation={viewingTransportation}
          viewingCustomClearance={viewingCustomClearance}
          viewFishingData={viewFishingData}
          viewLandingData={viewLandingData}
          viewWeightingData={viewWeightingData}
          viewAuctioningData={viewAuctioningData}
          viewTransportationData={viewTransportationData}
          viewCustomClearanceData={viewCustomClearanceData}
          {...state}
        />
      </div>
    </>
  );
};

export default PassportPage;
