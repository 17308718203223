import React from "react";
import { motion } from "framer-motion";
import Group482 from "../Group482";
import Group436 from "../Group436";
import Group433 from "../Group433";
import Group435 from "../Group435";
import Group428 from "../Group428";
import Group429 from "../Group429";
import "./lot-data-dialog.css";

const LotDataDialog = ({ onSave, onCancel }) => {
  return (
    <div className="center-dialog">
      <div className="overlay"></div>
      <div className="group-506">
        <div className="overlap-group11-19">
          <div className="rectangle-135-11"></div>
          <div className="lot-data-21 poppins-semi-bold-astronaut-17-5px">
            Lot Data
          </div>
          <Group482 className="group-431-6">Fishery name:</Group482>
          <Group482 className="group-432-5">Name market operator:</Group482>
          <Group436 />
          <Group482 className="group-434-3">Fish species:</Group482>
          <Group433 className="group-433-5" />
          <Group429 className="group-429-11" onClick={onCancel} />
          <img className="group-412-21" src="/img/group-412-21@2x.svg" />
          <Group435 className="group-435-3" />
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8 }}
            className="name-53 text"
          >
            James Miles
          </motion.div>
          <div className="small poppins-semi-bold-white-13px">
            <span className="poppins-semi-bold-tundora-13px"></span>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.8 }}
              className="span1-29 text"
            >
              Small
            </motion.div>
          </div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8 }}
            className="grade-a-7 text"
          >
            Grade A
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8 }}
            className="x13kg-9 text"
          >
            13kg
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8 }}
            className="st-georges-channel-3 text"
          >{`St George’s Channel`}</motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8 }}
            className="by-net-2 text"
          >
            By net
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8 }}
            className="phone-4 text"
          >
            0409954
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8 }}
            className="sealess-rock-4 text"
          >
            Sealess Rock
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8 }}
            className="name-54 text"
          >
            John Klopper
          </motion.div>
          <Group428
            className="group-428-4"
            onSave={onSave}
            onCancel={onCancel}
          />
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8 }}
            className="pollack-9 text"
          >
            Pollack
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default LotDataDialog;
