import React from "react";
import "./tasks.css";

function Tasks() {
  return (
    <div className="overlap-group12-23">
      <div className="rectangle-115-17 border-1px-mercury-2"></div>
      <div className="tasks-17 poppins-bold-mine-shaft-16px">Tasks</div>
      <div className="release-transportation-documents-17 poppins-normal-sonic-silver-13px">
        Release transportation documents
      </div>
      <div className="review-catch-certificate-xdf1-d3-fa-17 poppins-normal-sonic-silver-13px">
        Review catch certificate XDF1D3FA
      </div>
      <img className="vector-33-17" src="/img/vector-33@1x.svg" />
      <img className="vector-34-17" src="/img/vector-33@1x.svg" />
      <img className="vector-35-17" src="/img/vector-33@1x.svg" />
      <img className="vector-36-17" src="/img/vector-33@1x.svg" />
      <img className="vector-38-17" src="/img/vector-33@1x.svg" />
      <div className="review-lot-457258-dd-17 poppins-normal-sonic-silver-13px">
        Review Lot #457258DD
      </div>
      <div className="review-lot-876258-lm-17 poppins-normal-sonic-silver-13px">
        Review Lot #876258LM
      </div>
      <div className="sales-note-1255-17 poppins-normal-sonic-silver-13px">
        Sales Note #1255
      </div>
      <div className="view-141 poppins-normal-white-11px">View</div>
      <div className="view-142 poppins-normal-white-11px">View</div>
      <div className="view-143 poppins-normal-white-11px">View</div>
      <div className="view-144 poppins-normal-white-11px">View</div>
      <div className="view-145 poppins-normal-white-11px">View</div>
      <div className="overlap-group11-17">
        <div className="rectangle-133-17"></div>
        <div className="text-31-1 poppins-semi-bold-unmellow-yellow-15-5px">
          +
        </div>
      </div>
    </div>
  );
}

export default Tasks;
