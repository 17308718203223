import React from "react";
import "./Group488.css";

function Group488(props) {
  const { children, className } = props;

  return (
    <div className={`group-4-2 ${className || ""}`}>
      <p className="place-time-of-loading-xx poppins-medium-cod-gray-13px">
        {children}
      </p>
      <div className="rectangle-1 border-1px-mercury"></div>
    </div>
  );
}

export default Group488;
