import React from "react";
import "./Group4057.css";

function Group4057(props) {
  const { className } = props;

  return (
    <div
      className={`group-405 poppins-semi-bold-white-13px ${className || ""}`}
    >
      <p className="food-standart-approval-566-kd0993">
        <span className="span-20 poppins-semi-bold-tundora-13px">
          Food standart approval #:
        </span>
        <span className="span-20 poppins-medium-sonic-silver-13px">&nbsp;</span>
        <span className="span-21 text">566KD0993</span>
      </p>
      <div className="group-405-item">
        <span className="span-20 poppins-semi-bold-tundora-13px">
          Country origin:&nbsp;&nbsp;
        </span>
        <span className="span-21 text">Wales</span>
      </div>
      <p className="group-405-item">
        <span className="span-20 poppins-semi-bold-tundora-13px">
          Country of destination:&nbsp;&nbsp;
        </span>
        <span className="span-21 text">France</span>
      </p>
      <p className="group-405-item">
        <span className="span-20 poppins-semi-bold-tundora-13px">
          Fish latin name:&nbsp;&nbsp;
        </span>
        <span className="span-21 text">(Pollachius pollachius)</span>
      </p>
      <p className="group-405-item-1">
        <span className="span-20 poppins-semi-bold-tundora-13px">
          Place and time of loading:&nbsp;&nbsp;
          <br />
        </span>
        <span className="span-21 text span-newline">
          Wales, Porthcawl 10/06/2022&nbsp;&nbsp;05:03:06
        </span>
      </p>
      <p className="group-405-item-1">
        <span className="span-20 poppins-semi-bold-tundora-13px">
          Place and time destination: <br />
        </span>
        <span className="span-21 text span-newline">
          France, Tourlaville 16/11/2022&nbsp;&nbsp;13:15:08
        </span>
      </p>
      <p className="group-405-item">
        <span className="span-20 poppins-semi-bold-tundora-13px">
          Competent authority:&nbsp;&nbsp;
        </span>
        <span className="span-21 text">Welsh Porth Police</span>
      </p>
      <div className="group-405-item">
        <span className="span-20 poppins-semi-bold-tundora-13px">
          Health certificate:{" "}
        </span>
        <span className="span-21 text"> 5518HB830</span>
      </div>
      <p className="group-405-item-1">
        <span className="span-20 poppins-semi-bold-tundora-13px">
          Authority date &amp; signature:
          <br />
        </span>
        <span className="span-21 text span-newline">
          Sammuel L.&nbsp;&nbsp;13/11/2022
        </span>
      </p>
      <div className="group-405-item">
        <span className="span-20 poppins-semi-bold-tundora-13px">
          ISO code:{" "}
        </span>
        <span className="span-21 text">W4D87K3330</span>
      </div>
    </div>
  );
}

export default Group4057;
