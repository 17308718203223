import React from "react";
import Card from "../Card";
import TabLineItem2 from "../TabLineItem2";
import TabLineItem3 from "../TabLineItem3";
import Content from "../Content";
import "./client-statistic.css";

function ClientStatistic(props) {
  const {
    cardProps,
    tabLineItem2Props,
    tabLineItem31Props,
    tabLineItem32Props,
    contentProps,
  } = props;

  return (
    <div className="overlap-group1-61">
      <Card pillsProps={cardProps.pillsProps} />
      <TabLineItem2 contentProps={tabLineItem2Props.contentProps} />
      <TabLineItem3 contentProps={tabLineItem31Props.contentProps} />
      <TabLineItem3
        className={tabLineItem32Props.className}
        contentProps={tabLineItem32Props.contentProps}
      />
      <Content className={contentProps.className}>
        {contentProps.children}
      </Content>
      <div className="chart-name-56 poppins-bold-mirage-16px">
        Client Statistic
      </div>
      <div className="chart-9-17">
        <div className="overlap-group-161">
          <img className="vector-8-17" src="/img/vector-8-17@1x.svg" />
          <img className="vector-28-17" src="/img/vector-28-17@1x.svg" />
          <img className="vector-9-17" src="/img/vector-9-17@1x.svg" />
          <img className="vector-21-17" src="/img/vector-21@2x.svg" />
          <img className="vector-22-17" src="/img/vector-22@2x.svg" />
          <img className="vector-23-17" src="/img/vector-23@2x.svg" />
          <img className="vector-24-17" src="/img/vector-24@2x.svg" />
          <img className="vector-25-17" src="/img/vector-25@2x.svg" />
          <img className="vector-26-17" src="/img/vector-26@2x.svg" />
          <img className="vector-27-17" src="/img/vector-27@2x.svg" />
          <div className="ellipse-42-17 border-4px-white"></div>
        </div>
        <div className="flex-row-60">
          <div className="name-47 sfprodisplay-medium-gun-powder-12px">Jan</div>
          <div className="flex-row-item-17 sfprodisplay-medium-gun-powder-12px">
            Feb
          </div>
          <div className="place-37 sfprodisplay-medium-gun-powder-12px">
            Mar
          </div>
          <div className="apr-17 sfprodisplay-normal-gun-powder-12px">Apr</div>
          <div className="name-48 sfprodisplay-medium-gun-powder-12px">May</div>
          <div className="flex-row-item-17 sfprodisplay-medium-gun-powder-12px">
            Jun
          </div>
          <div className="jul-17 sfprodisplay-medium-gun-powder-12px">Jul</div>
        </div>
      </div>
      <img
        className="flag_of_the_-netherlands-1-17"
        src="/img/flag-of-the-netherlands-1@2x.png"
      />
    </div>
  );
}

export default ClientStatistic;
