import React from "react";
import { motion } from "framer-motion";
import Group482 from "../Group482";
import Group438 from "../Group438";
import Group437 from "../Group437";
import Group429 from "../Group429";
import Group428 from "../Group428";
import "./sales-note-dialog.css";

const SalesNoteDialog = ({ onSave, onCancel }) => {
  return (
    <div className="center-dialog">
      <div className="number-48 text">1</div>
      <div className="overlay"></div>
      <div className="overlap-group12-14">
        <div className="rectangle-135-8"></div>
        <div className="sales-note-14 poppins-semi-bold-astronaut-17-5px">
          Sales Note
        </div>
        <Group482 className="group-431-3">Fishery name:</Group482>
        <Group482 className="group-432-2">Client name:</Group482>
        <Group438 className="group-438-4" />
        <Group482 className="group-434">Fish species:</Group482>
        <Group437 totalWeight="Total weight:" className="group-437-6" />
        <Group429 className="group-429-8" onClick={onCancel} />
        <div className="name-38 text">James Miles</div>
        <div className="x13kg-5 poppins-semi-bold-ocean-blue-pearl-13px">
          <span className="poppins-semi-bold-ocean-blue-pearl-13px"></span>
          <span className="span1-24 text">13kg</span>
        </div>
        <div className="grade-a-4 text">Grade A</div>
        <div className="pollack-6 text">Pollack</div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          className="devante-ltd-8 text"
        >
          Devante Ltd
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          className="x13kg-6 text"
        >
          13kg
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          className="number-49 text"
        >
          1
        </motion.div>
        <Group428 className="group-428-3" onSave={onSave} onCancel={onCancel} />
      </div>
    </div>
  );
};

export default SalesNoteDialog;
