import React from "react";
import Flag from "../Flag";
import "./Addon.css";

function Addon(props) {
  const { flagProps } = props;

  return (
    <div className="first-addon">
      <Flag src={flagProps.src} />
    </div>
  );
}

export default Addon;
