import React from "react";
import "./Group435.css";

function Group435(props) {
  const { className } = props;

  return (
    <div className={`group-435 ${className || ""}`}>
      <div className="flex-col-2">
        <div className="catch-area poppins-semi-bold-mine-shaft-13px">Catch area:</div>
        <div className="rectangle-138-1 border-1px-mercury"></div>
      </div>
      <div className="flex-col-3">
        <div className="catch-method poppins-semi-bold-mine-shaft-13px">Catch method:</div>
        <div className="rectangle-141 border-1px-mercury"></div>
      </div>
    </div>
  );
}

export default Group435;
