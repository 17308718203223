import React from "react";
import "./transportation-step-data.css";

const TransportationStepData = () => {
  return (
    <div className="group-397 poppins-semi-bold-white-13px">
      <div className="group-384">
        <div className="transportation-2 poppins-semi-bold-astronaut-15px">
          Transportation
        </div>
        <img className="transportation-icon" src="/img/transp-icon.svg" />
      </div>
      <p className="client-name-devante-ltd-6">
        <span className="poppins-semi-bold-tundora-13px">
          Client name:&nbsp;&nbsp;
        </span>
        <span className="span-3 text">Devante Ltd</span>
      </p>
      <p className="group-397-item">
        <span className="poppins-semi-bold-tundora-13px">
          Fish species:&nbsp;&nbsp;{" "}
        </span>
        <span className="span-3 text">Pollack</span>
      </p>
      <p className="group-397-item">
        <span className="poppins-semi-bold-tundora-13px">
          Fishery name:&nbsp;&nbsp;
        </span>
        <span className="span-3 text">James Miles</span>
      </p>
      <p className="group-397-item">
        <span className="poppins-semi-bold-tundora-13px">
          Transporter name:&nbsp;&nbsp;{" "}
        </span>
        <span className="span-3 text">Miles Fast Ltd</span>
      </p>
      <div className="group-397-item">
        <span className="poppins-semi-bold-tundora-13px">Box weight: </span>
        <span className="span-3 text">460kg</span>
      </div>
      <div className="group-397-item">
        <span className="poppins-semi-bold-tundora-13px">
          Food standart approval:
        </span>
        <span className="poppins-semi-bold-cod-gray-13px">&nbsp;</span>
        <span className="span-3 text">#26DGKLG78</span>
      </div>
      <div className="group-397-item">
        <span className="poppins-semi-bold-tundora-13px">
          Country origin:&nbsp;&nbsp;
        </span>
        <span className="span-3 text">France</span>
      </div>
      <p className="group-397-item">
        <span className="poppins-semi-bold-tundora-13px">
          Country of destination:&nbsp;&nbsp;
        </span>
        <span className="span-3 text">France</span>
      </p>
      <p className="place-and-time">
        <span className="poppins-semi-bold-tundora-13px">
          Place and time of loading:&nbsp;&nbsp;
          <br />
        </span>
        <span className="span-3 text">
          Wales, Porthcawl 10/06/2022&nbsp;&nbsp;05:03:06
        </span>
      </p>
      <p className="place-and-time">
        <span className="poppins-semi-bold-tundora-13px">
          Place and time destination: <br />
        </span>
        <span className="span-3 text">
          France, Tourlaville 16/11/2022&nbsp;&nbsp;13:15:08
        </span>
      </p>
    </div>
  );
};

export default TransportationStepData;
