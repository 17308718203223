import React from "react";
import PillsItem from "../PillsItem";
import PillsItem2 from "../PillsItem2";
import PillsItem3 from "../PillsItem3";
import "./Pills.css";

function Pills(props) {
  const { pillsItemProps, pillsItem2Props, pillsItem3Props } = props;

  return (
    <div className="pills">
      <PillsItem pillsContentProps={pillsItemProps.pillsContentProps} />
      <PillsItem2 pillsContentProps={pillsItem2Props.pillsContentProps} />
      <PillsItem3 pillsContentProps={pillsItem3Props.pillsContentProps} />
    </div>
  );
}

export default Pills;
