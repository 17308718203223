import React from "react";
import { motion } from "framer-motion";

const VerifyIconAnimated = (props) => (
  <motion.div
    animate={{ rotate: 360 }}
    transition={{ repeat: Infinity, duration: 1 }}
    style={{ fill: "#fff" }}
    {...props}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      style={{ color: "#fff" }}
    >
      <path d="M12 4V2A10 10 0 002 12h2a8 8 0 018-8z" />
    </svg>
  </motion.div>
);

export default VerifyIconAnimated;
