import React from "react";
import "./Group457.css";

function Group457(props) {
  const { children, className } = props;

  return (
    <div className={`group-457 ${className || ""}`}>
      <div className="master-signature poppins-semi-bold-mine-shaft-13px">{children}</div>
      <div className="rectangle-145-3 border-1px-mercury"></div>
    </div>
  );
}

export default Group457;
