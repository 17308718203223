import React from "react";
import "./Group438.css";

function Group438(props) {
  const { className } = props;

  return (
    <div className={`group-438 ${className || ""}`}>
      <div className="flex-col-22">
        <div className="lot-grade poppins-semi-bold-mine-shaft-13px">Lot grade:</div>
        <div className="rectangle-139 border-1px-mercury"></div>
      </div>
      <div className="flex-col-23">
        <div className="lot-weight poppins-semi-bold-mine-shaft-13px">Lot weight:</div>
        <div className="rectangle-137-1 border-1px-mercury"></div>
      </div>
    </div>
  );
}

export default Group438;
