import React from "react";
import FishingStepData from "../PassportStepsGenerated/FishingStepData";
import LandingStepData from "../PassportStepsGenerated/LandingStepData";
import WeightingStepData from "../PassportStepsGenerated/WeightingStepData";
import AuctioningStepData from "../PassportStepsGenerated/AuctioningStepData";
import TransportationStepData from "../PassportStepsGenerated/TransportationStepData";
import CustomClearanceStepData from "../PassportStepsGenerated/CustomClearanceStepData";
import "./digital-passport-step-data.css";
import { AnimatePresence, motion } from "framer-motion";

const StepData = (props) => {
  const {
    viewingFishing,
    viewingLanding,
    viewingWeighting,
    viewingAuctioning,
    viewingTransportation,
    viewingCustomClearance,
  } = props;

  return (
    <div className="overlap-group13-15 border-1px-x5">
      <img className="pollock1-1-2" src="/img/pollock1-1@2x.png" />
      <div className="frame-step-data">
        <img src="/img/vector-1@2x.svg" />
        <AnimatePresence>
          {viewingFishing && (
            <motion.div
              key="fishing-step-id"
              initial={{ y: 100, position: "absolute", opacity: 0 }}
              animate={{ y: 0, position: "static", opacity: 1 }}
              exit={{ y: -100, position: "absolute", zIndex: -10, opacity: 0 }}
              duration={{ type: "tween" }}
            >
              <FishingStepData />
            </motion.div>
          )}
          {viewingLanding && (
            <motion.div
              key="landing-step-id"
              initial={{ y: 100, position: "absolute", opacity: 0 }}
              animate={{ y: 0, position: "static", opacity: 1 }}
              exit={{ y: -100, position: "absolute", zIndex: -10, opacity: 0 }}
              duration={{ type: "tween" }}
            >
              <LandingStepData />
            </motion.div>
          )}
          {viewingWeighting && (
            <motion.div
              key="weighting-step-id"
              initial={{ y: 100, position: "absolute", opacity: 0 }}
              animate={{ y: 0, position: "static", opacity: 1 }}
              exit={{ y: -100, position: "absolute", zIndex: -10, opacity: 0 }}
              duration={{ type: "tween" }}
            >
              <WeightingStepData />
            </motion.div>
          )}
          {viewingAuctioning && (
            <motion.div
              key="auctioning-step-id"
              initial={{ y: 100, position: "absolute", opacity: 0 }}
              animate={{ y: 0, position: "static", opacity: 1 }}
              exit={{ y: -100, position: "absolute", zIndex: -10, opacity: 0 }}
              duration={{ type: "tween" }}
            >
              <AuctioningStepData />
            </motion.div>
          )}
          {viewingTransportation && (
            <motion.div
              key="transportation-step-id"
              initial={{ y: 100, position: "absolute", opacity: 0 }}
              animate={{ y: 0, position: "static", opacity: 1 }}
              exit={{ y: -100, position: "absolute", zIndex: -10, opacity: 0 }}
              duration={{ type: "tween" }}
            >
              <TransportationStepData />
            </motion.div>
          )}
          {viewingCustomClearance && (
            <motion.div
              key="custom-step-id"
              initial={{ y: 100, position: "absolute", opacity: 0 }}
              animate={{ y: 0, position: "static", opacity: 1 }}
              exit={{ y: -100, position: "absolute", zIndex: -10, opacity: 0 }}
              duration={{ type: "tween" }}
            >
              <CustomClearanceStepData />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default StepData;
