import { motion, AnimatePresence } from "framer-motion";
import React, { useState } from "react";
import Group4072 from "../../Group4072";
import Group405 from "../Group405";
import Group4057 from "../Group4057";
import "./Group400.css";

function Group400() {
  const [page, setPage] = useState(0);
  const nextPage = () => (page === 2 ? setPage(0) : setPage(page + 1));
  return (
    <>
      <div className="group-400 poppins-semi-bold-white-13px">
        <AnimatePresence>
          {page === 0 && (
            <motion.div
              key="custom-1-step-id"
              initial={{ y: 100, position: "absolute", opacity: 0 }}
              animate={{ y: 0, position: "static", opacity: 1 }}
              exit={{ y: -100, position: "absolute", zIndex: -10, opacity: 0 }}
              duration={{ type: "tween" }}
            >
              <div className="group-385">
                <div className="custom-clearance-2 poppins-semi-bold-astronaut-15px">
                  Custom clearance
                </div>
                <img className="group-78-1" src="/img/custom-cl-icon.svg" />
              </div>
              <div className="catch-certificate-k234-h5685-dfh99-xx">
                <span className="poppins-semi-bold-tundora-13px">
                  Catch certificate:&nbsp;&nbsp;
                </span>
                <span className="span1-14 text">#K234H5685DFH99</span>
              </div>
              <p className="group-400-item">
                <span className="poppins-semi-bold-tundora-13px">
                  Validating entity:&nbsp;&nbsp;
                </span>
                <span className="span1-14 text">Porthcawl SD</span>
              </p>
              <div className="group-400-item">
                <span className="poppins-semi-bold-tundora-13px">
                  Exporter name:&nbsp;&nbsp;
                </span>
                <span className="span1-14 text">ICE.</span>
              </div>
              <p className="exporter-address-2">
                <span className="poppins-semi-bold-tundora-13px">
                  Exporter address: <br />
                </span>
                <span className="span1-14 text span-newline">
                  2-4 Dock St, Porthcawl CF36 3BL, UK
                </span>
              </p>
              <div className="group-400-item">
                <span className="poppins-semi-bold-tundora-13px">
                  Date of issuance:{" "}
                </span>
                <span className="span1-14 text">12/11/2022</span>
              </div>
              <p className="group-400-item">
                <span className="poppins-semi-bold-tundora-13px">
                  FAO area:&nbsp;&nbsp;
                </span>
                <span className="span1-14 text">St George’s Channel</span>
              </p>
              <p className="group-400-item">
                <span className="poppins-semi-bold-tundora-13px">
                  Master of vessel:&nbsp;&nbsp;
                </span>
                <span className="span1-14 text">Bentley Grey</span>
              </p>
              <p className="group-400-item">
                <span className="poppins-semi-bold-tundora-13px">
                  Master signature:&nbsp;&nbsp;
                </span>
                <span className="poppins-medium-sonic-silver-13px">
                  Bentley Grey
                </span>
              </p>
              <div className="group-400-item">
                <span className="poppins-semi-bold-tundora-13px">
                  Licence number:{" "}
                </span>
                <span className="span1-14 text">3205911H3S</span>
              </div>
            </motion.div>
          )}
          {page === 1 && (
            <motion.div
              key="custom-2-step-id"
              initial={{ y: 100, position: "absolute", opacity: 0 }}
              animate={{ y: 0, position: "static", opacity: 1 }}
              exit={{ y: -100, position: "absolute", zIndex: -10, opacity: 0 }}
              duration={{ type: "tween" }}
            >
              <Group405 />
            </motion.div>
          )}
          {page === 2 && (
            <motion.div
              key="custom-3-step-id"
              initial={{ y: 100, position: "absolute", opacity: 0 }}
              animate={{ y: 0, position: "static", opacity: 1 }}
              exit={{ y: -100, position: "absolute", zIndex: -10, opacity: 0 }}
              duration={{ type: "tween" }}
            >
              <Group4057 />
            </motion.div>
          )}
        </AnimatePresence>
        <Group4072 onClick={nextPage} page={page} />
      </div>
    </>
  );
}

export default Group400;
