import React, { useState } from "react";
import clsx from "clsx";
import { motion } from "framer-motion";
import Tooltip from "@mui/material/Tooltip";
import { useClipboard } from "use-clipboard-copy";
import VerifyIconAnimated from "./VerifyIconAnimated";
import IssuerInfo from "../IssuerInfo";
import "./verifiable-file-data.css";

const getTxUrlByStep = ({
  viewingFishingLanding,
  viewingWeighting,
  viewingAuctioning,
  viewingTransportation,
  viewingCustomClearance,
}) => {
  if (viewingFishingLanding)
    return "https://etherscan.io/tx/0xb0fbc0afb845653fdd4f95b3b31ba16c61cc2ef79b42f6edfc3def89a830aaf5";
  if (viewingWeighting)
    return "https://etherscan.io/tx/0xb0fbc0afb845653fdd4f95b3b31ba16c61cc2ef79b42f6edfc3def89a830aaf5";
  if (viewingAuctioning)
    return "https://etherscan.io/tx/0xb0fbc0afb845653fdd4f95b3b31ba16c61cc2ef79b42f6edfc3def89a830aaf5";
  if (viewingTransportation)
    return "https://etherscan.io/tx/0xb0fbc0afb845653fdd4f95b3b31ba16c61cc2ef79b42f6edfc3def89a830aaf5";
  if (viewingCustomClearance)
    return "https://etherscan.io/tx/0xb0fbc0afb845653fdd4f95b3b31ba16c61cc2ef79b42f6edfc3def89a830aaf5";
  return "#";
};

const getTxHashByStep = ({
  viewingFishingLanding,
  viewingWeighting,
  viewingAuctioning,
  viewingTransportation,
  viewingCustomClearance,
}) => {
  if (viewingFishingLanding)
    return "0xb0fbc0afb845653fdd4f95b3b31ba16c61cc2ef79b42f6edfc3def89a830aaf5";
  if (viewingWeighting)
    return "0xb0fbc0afb845653fdd4f95b3b31ba16c61cc2ef79b42f6edfc3def89a830aaf5";
  if (viewingAuctioning)
    return "0xb0fbc0afb845653fdd4f95b3b31ba16c61cc2ef79b42f6edfc3def89a830aaf5";
  if (viewingTransportation)
    return "0xb0fbc0afb845653fdd4f95b3b31ba16c61cc2ef79b42f6edfc3def89a830aaf5";
  if (viewingCustomClearance)
    return "0xb0fbc0afb845653fdd4f95b3b31ba16c61cc2ef79b42f6edfc3def89a830aaf5";
  return "";
};

const VerifiableFileData = (props) => {
  const {
    isVerified,
    onVerify,
    isLoading,
    issuedDate,
    proofFile,
    downloadFile,
    viewingFishingLanding,
    viewingWeighting,
    viewingAuctioning,
    viewingTransportation,
    viewingCustomClearance,
  } = props;
  const [isCopied, setIsCopied] = useState(false);
  const clipboard = useClipboard();

  const onCopy = () => {
    clipboard.copy(
      getTxHashByStep({
        viewingFishingLanding,
        viewingWeighting,
        viewingAuctioning,
        viewingTransportation,
        viewingCustomClearance,
      })
    );
    setIsCopied(true);
    const t = setTimeout(() => {
      setIsCopied(false);
      clearTimeout(t);
    }, 1000);
  };

  return (
    <div className="passport-data-container">
      <div className="passport-data-inner-container">
        <div className="inner-col-1">
          <div className="inner-col-2">
            <div className="overlap-group1-1">
              <div className="passport-file-title">
                <span className="enable-label-1 opensans-bold-mine-shaft-22px">
                  PASSPORT #134B
                </span>
                {isVerified && (
                  <motion.img
                    className="file-verified-icon"
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    src="/img/111-1@2x.svg"
                  />
                )}
              </div>
              <a
                className="small-download-button-container"
                href={downloadFile.path}
                download={downloadFile.filename}
              >
                <img
                  src="/img/download-icon-blue.svg"
                  className="small-download-button-icon"
                />
              </a>
            </div>
            <IssuerInfo />
            <div className="flex-row-1">
              <img className="popularc" src="/img/popular-calendar@2x.svg" />
              <div className="edit-title valign-text-middle opensans-bold-mine-shaft-12px">
                ISSUED ON
              </div>
              <div className="overlap-group2-1">
                <div className="enable-label-2 valign-text-middle roboto-normal-abbey-14px">
                  {issuedDate}
                </div>
                {isVerified && (
                  <motion.img
                    className="verify-issuer-check-icon"
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    src="/img/popular-check-3@2x.svg"
                  />
                )}
              </div>
            </div>
            <div className="flex-row-2">
              <img className="popularc" src="/img/popular-clock@2x.svg" />
              <div className="file-name-1 valign-text-middle opensans-bold-mine-shaft-12px">
                DOES NOT EXPIRE
              </div>
            </div>
            <div className="buttons-container">
              <div
                className={clsx(
                  "verify-button",
                  isVerified && "verified-button"
                )}
                onClick={(!isVerified && onVerify) || null}
              >
                {!isVerified && !isLoading && (
                  <img className="policy-icon" src="/img/policy@2x.svg" />
                )}
                {isVerified && !isLoading && (
                  <img className="verified-icon" src="/img/112@2x.svg" />
                )}
                {isLoading && <VerifyIconAnimated className="policy-icon" />}
                <div className="verify-text opensans-bold-white-14-6px">
                  {isVerified ? "VERIFIED" : "VERIFY"}
                </div>
              </div>
              {isVerified && (
                <>
                  <a
                    href={proofFile.path}
                    download={proofFile.filename}
                    className="proof-file-button"
                  >
                    <img
                      className="proof-file-cloud-icon"
                      src="/img/cloud-download@2x.svg"
                    />
                    <span className="proof-file-text opensans-bold-white-14-6px">
                      PROOF FILE
                    </span>
                  </a>
                  <div style={{ width: "100%" }}>
                    <div className="transaction-id-field-2">
                      <div className="poppins-494949-16px transaction-id-field-label">
                        <span>Transaction hash</span>
                        <Tooltip
                          title={
                            <span className="poppins-normal-white-12px">
                              Copied
                            </span>
                          }
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          placement="top"
                          arrow
                          open={isCopied}
                        >
                          <svg
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            onClick={onCopy}
                            className="hash-tooltip"
                          >
                            <circle cx="11" cy="11" r="11" fill="#494949" />
                            <path
                              d="M11.5608 5.3877H7.63221C7.33452 5.3877 7.04902 5.50595 6.83852 5.71645C6.62802 5.92695 6.50977 6.21245 6.50977 6.51014V15.4897C6.50977 15.7874 6.62802 16.0729 6.83852 16.2834C7.04902 16.4939 7.33452 16.6122 7.63221 16.6122H14.3669C14.6646 16.6122 14.9501 16.4939 15.1606 16.2834C15.3711 16.0729 15.4894 15.7874 15.4894 15.4897V9.31627M11.5608 5.3877L15.4894 9.31627M11.5608 5.3877V9.31627H15.4894"
                              stroke="white"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </Tooltip>
                      </div>
                      <span className="transaction-id-field-value-2">
                        {getTxHashByStep({
                          viewingFishingLanding,
                          viewingWeighting,
                          viewingAuctioning,
                          viewingTransportation,
                          viewingCustomClearance,
                        })}
                      </span>
                    </div>
                    <a
                      className="transaction-url-field-2"
                      href={getTxUrlByStep({
                        viewingFishingLanding,
                        viewingWeighting,
                        viewingAuctioning,
                        viewingTransportation,
                        viewingCustomClearance,
                      })}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="poppins-494949-16px">Transaction url</div>
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="11" cy="11" r="11" fill="#494949" />
                        <path
                          d="M12.5 7.7502V6.5577C12.5 5.8902 13.31 5.5527 13.7825 6.0252L17.225 9.4677C17.5175 9.7602 17.5175 10.2327 17.225 10.5252L13.7825 13.9677C13.31 14.4402 12.5 14.1102 12.5 13.4427V12.1752C8.74996 12.1752 6.12496 13.3752 4.24996 16.0002C4.99996 12.2502 7.24996 8.5002 12.5 7.7502Z"
                          fill="white"
                        />
                      </svg>
                    </a>
                  </div>
                </>
              )}
            </div>
            <div className="file-name-2 valign-text-middle opensans-semi-bold-mine-shaft-18px">
              SHARE
            </div>
            <div className="share-buttons-container">
              <div className="share-button share-copy-button">
                <img src="/img/share-hyperlink.svg" />
              </div>
              <div className="share-button share-twitter-button">
                <img src="/img/share-twitter.svg" />
              </div>
              <div className="share-button share-facebook-button">
                <img src="/img/share-facebook.svg" />
              </div>
              <div className="share-button share-plus-button">
                <img src="/img/share-plus.svg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifiableFileData;
