import React from "react";
import "./Group405.css";

function Group405(props) {
  const { className } = props;

  return (
    <div
      className={`group-405 poppins-semi-bold-white-13px ${className || ""}`}
    >
      <p className="fish-species-pollack">
        <span className="span-20 poppins-semi-bold-tundora-13px">
          Fish species:&nbsp;&nbsp;{" "}
        </span>
        <span className="span-21 text">Pollack</span>
      </p>
      <div className="group-405-item">
        <span className="span-20 poppins-semi-bold-tundora-13px">
          Lot weight:{" "}
        </span>
        <span className="span-21 text">13kg</span>
      </div>
      <p className="group-405-item">
        <span className="span-20 poppins-semi-bold-tundora-13px">
          Fishery name:&nbsp;&nbsp;
        </span>
        <span className="span-21 text">James Miles</span>
      </p>
      <div className="group-405-item">
        <span className="span-20 poppins-semi-bold-tundora-13px">
          Lot grade:{" "}
        </span>
        <span className="span-21 text">Grade A</span>
      </div>
      <div className="group-405-item-1">
        <span className="span-20 poppins-semi-bold-tundora-13px">PLN: </span>
        <span className="span-21 text">
          Boat: 0409954
          <br />
          <span className="span-newline">Sealess Rock</span>
        </span>
      </div>
      <p className="group-405-item">
        <span className="span-20 poppins-semi-bold-tundora-13px">
          # of boxes:&nbsp;&nbsp;
        </span>
        <span className="span-21 text">1</span>
      </p>
      <p className="group-405-item">
        <span className="span-20 poppins-semi-bold-tundora-13px">
          Client name:&nbsp;&nbsp;
        </span>
        <span className="span-21 text">Devante Ltd</span>
      </p>
      <div className="group-405-item">
        <span className="span-20 poppins-semi-bold-tundora-13px">
          Total weight:&nbsp;&nbsp;
        </span>
        <span className="span-21 text">13kg</span>
      </div>
      <p className="group-405-item">
        <span className="span-20 poppins-semi-bold-tundora-13px">
          Catch area:&nbsp;&nbsp;{" "}
        </span>
        <span className="span-21 text">St George’s Channel</span>
      </p>
      <p className="group-405-item">
        <span className="span-20 poppins-semi-bold-tundora-13px">
          Catch method:&nbsp;&nbsp;
        </span>
        <span className="span-21 text">By net</span>
      </p>
      <p className="group-405-item">
        <span className="span-20 poppins-semi-bold-tundora-13px">
          Name market operator:{" "}
        </span>
        <span className="span-21 text">John Klopper</span>
      </p>
    </div>
  );
}

export default Group405;
