import React from "react";
import "./Content.css";

function Content(props) {
  const { children, className } = props;

  return (
    <div className={`content-40 ${className || ""}`}>
      <div className="text-21 inter-normal-mirage-14px">{children}</div>
    </div>
  );
}

export default Content;
